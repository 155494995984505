import {
    UPDATE_FILTERS,
    RESET_FILTERS
} from '../actions/actions';

const initialState = {
    projectTypeIds: [],
    projectTypePartnerIds: [],
    string: ''
};

export const projectFilters = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case UPDATE_FILTERS: {
            const { update } = action;

            return {
                ...state,
                ...update
            };
        }
        case RESET_FILTERS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
