import hzpcIcon from './assets/icons/HZPC-logo.png';
import farmingIcon from './assets/icons/farming.svg';
import schoolIcon from './assets/icons/school.svg';
import treesIcon from './assets/icons/trees.svg';
import unicefIcon from './assets/icons/unicef-logo.svg';
import farmingPin from './assets/pin/farming.svg';
import hzpcPin from './assets/pin/HZPC.svg';
import schoolPin from './assets/pin/school.svg';
import treesPin from './assets/pin/trees.svg';
import unicefPin from './assets/pin/Unicef.svg';
import videoPin from './assets/pin/video.svg';

export const CesiumIonAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3YmMwZGZkYi1hMzdiLTQ2NDUtOTQ0OS0zMjViMDk0YmU0ZjQiLCJpZCI6MzYwMDcsImlhdCI6MTYwMjgzNzY5N30.MQXhOeZrvIlOOHN946FO2rQuFfc_Tkwoi5gP6LFVzow';
export const Partner = {
  Icon: {
    1: hzpcIcon,
    2: unicefIcon
  },
  Pin: {
    1: hzpcPin,
    2: unicefPin
  }
};
export const ProjectType = {
  Icon: {
    1: schoolIcon,
    2: farmingIcon,
    3: treesIcon,
    4: hzpcIcon
  },
  Pin: {
    1: schoolPin,
    2: farmingPin,
    3: treesPin,
    4: hzpcPin
  }
};
