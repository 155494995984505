import { SET_VISIBLE_LAYERS, SET_HOME_LOCATION, SET_SELECTED_ENTITY, SET_SELECTED_LOCATION } from "../actions/actions";

const initialState = {
    focusedProject: null,
    hasLoaded: false,
    start: false,
    showProjects: true,
    showCountries: true,
    visibleLayer: null,
    selectedEntity: null,
    selectedLocation: null
};

export function globe(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SET_SELECTED_ENTITY: {
            const { selectedEntity } = action;
            return {
                ...state,
                selectedEntity
            }
        }
        case SET_SELECTED_LOCATION: {
            const { selectedLocation } = action;
            return {
                ...state,
                selectedLocation
            }
        }
        case SET_VISIBLE_LAYERS: {
            const { update } = action;
            return {
                ...state,
                ...update
            }
        }
        case 'LOADED': {
            return {
                ...state,
                hasLoaded: true,
            };
        }
        case 'START': {
            return {
                ...state,
                start: true,
            };
        }
        case 'FOCUS': {
            return {
                ...state,
                focusedProject: payload,
            };
        }
        default:
        return state;
    };
}
