import React from 'react';
import {
    Cartesian3,
    VerticalOrigin,
    HorizontalOrigin
} from 'cesium';
import {
    Entity
} from 'resium';
import {
    getBillboardEyeOffset,
    getBillboardVisibility
} from '../globeUtils';
import { ProjectType } from '../enums';


export function ProjectEntity({
    camera,
    project
}) {
    const position = Cartesian3.fromDegrees(project.lng, project.lat);
    const billboard = {
        eyeOffset: camera ? getBillboardEyeOffset(camera) : new Cartesian3(0, 0, -5000000),
        show: camera ? getBillboardVisibility(position, camera) : true,
        image: ProjectType.Pin[project.typeId],
        scale: 0.8,
        // scaleByDistance: new NearFarScalar(10000, 5.0, 800000, 0.5),
        // heightReference: HeightReference.CLAMP_TO_GROUND,
        // disableDepthTestDistance: Number.POSITIVE_INFINITY,
        verticalOrigin: VerticalOrigin.BOTTOM,
        horizontalOrigin: HorizontalOrigin.CENTER
    };

    return (
        <Entity
            key={project.id}
            position={position}
            project={ project }
            // name={project.name}
            billboard={billboard}>
        </Entity>
    );
}