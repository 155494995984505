import {
    COMPANIES_REQUEST_SUCCESS
} from '../actions/actions';

const initialState = {
    companies: []
};

export function companies(state = initialState, action) {
    switch(action.type) {
        case COMPANIES_REQUEST_SUCCESS: {
            const { companies } = action;
            return {
                ...state,
                companies
            };
        }
        default: {
            return state;
        }
    }
}
