import {
  PROJECT_TYPES_REQUEST,
  PROJECT_TYPES_REQUEST_SUCCESS,
  PROJECT_TYPES_REQUEST_FAILURE
} from './actions';
import { api } from '../api';
import { updateFilters } from './projectFilters';

// Get all project types
export function fetchProjectTypes() {
    return async dispatch => {
        const url = 'project-types';
        dispatch(onFetchProjectTypes());
        try {
            const response = await api.get(url);
            const { projectTypes } = response.data;
            dispatch(onFetchProjectTypesSuccess(projectTypes));
            dispatch(updateFilters({
                // Preselect only those unrelated to a (partner) company
                projectTypeIds: projectTypes ? projectTypes.filter((type) => !type.companyId).map((type) => type.id) : []
            }));
        } catch(error) {
            dispatch(onFetchProjectTypesFailure(error));
        }
    };
}

function onFetchProjectTypes() {
    console.log('[onFetchProjectTypes]');
    return {
        type: PROJECT_TYPES_REQUEST
    };
}

function onFetchProjectTypesSuccess(projectTypes) {
    console.log('[onFetchProjectTypesSuccess]', projectTypes);
    return {
        type: PROJECT_TYPES_REQUEST_SUCCESS,
        projectTypes
    };
}

function onFetchProjectTypesFailure(error) {
    console.log('[onFetchProjectTypesFailure]', error);
    return {
        type: PROJECT_TYPES_REQUEST_FAILURE,
        error
    };
}
