import React, { useEffect } from 'react';
import ReactSidebar from 'react-sidebar';
import SidebarHeader from './SidebarHeader';
import { isTouchDevice } from '../utils';

import './css/Sidebar.css';

function handleTouchMove(e) {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.some((element) => /ReactSwipeButton/gi.test(element.className))) {
        e.stopPropagation(true);
    }
}

export default function Sidebar({
    headerOptions,
    content,
    open,
    onHide,
    ...rest
}) {
    useEffect(() => {
        let sidebarElements = document.getElementsByClassName('sidebar-sidebar');

        for (let i = 0; i < sidebarElements.length; i++) {
            sidebarElements[i].addEventListener('touchmove', handleTouchMove);
        }

        return () => {
            for (let i = 0; i < sidebarElements.length; i++) {
                sidebarElements[i].removeEventListener('touchmove', handleTouchMove);
            }
        };
    }, []);

    return (
        <ReactSidebar
            rootClassName={`sidebar-root ${isTouchDevice() && !open ? 'hidden' : ''}`}
            sidebarClassName="sidebar-sidebar"
            contentClassName="sidebar-content"
            overlayClassName="sidebar-overlay"
            sidebar={
                <>
                    <SidebarHeader onHide={ onHide } { ...headerOptions } />
                    <div className="sidebar-sidebar-content">
                        { content }
                    </div>
                </>
            }
            open={ open }
            styles={{ sidebar: { background: '#fff' } }}
            touch={ false }
            { ...rest }
        >
        </ReactSidebar>
    );
}
