import { stripTrailingSlashes } from './utils';

const baseURL = stripTrailingSlashes(process.env.REACT_APP_API_URL);

const getPaymentMethods = options => {
  const { userId } = options;

  return window
    .fetch(`${baseURL}/users/${userId}/payment-methods`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log('API error:', { data });
        throw new Error('PaymentMethod API Error');
      } else {
        return data.data;
      }
    });
};

const createPaymentMethod = options => {
  const { userId, payment_method } = options;

  return window
    .fetch(`${baseURL}/users/${userId}/payment-methods`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payment_method)
    })
    .then(() => {
      return null;
    });
};

const deletePaymentMethod = options => {
  const { userId, paymentMethodId } = options;

  return window
    .fetch(`${baseURL}/users/${userId}/payment-methods/${paymentMethodId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
        'Content-Type': 'application/json'
      }
    })
    .then(() => {
      return null;
    });
};

const createPaymentIntent = options => {
  return window
    .fetch(`${baseURL}/payment-intents`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${ localStorage.getItem('token') }`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log('API error:', { data });
        throw new Error('PaymentIntent API Error');
      } else {
        return data.client_secret;
      }
    });
};

const getDonationOption = options => {
  const { donationOptionId, projectId } = options;

  return window
    .fetch(`${baseURL}/projects/${projectId}/donation-options/${donationOptionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log('API error:', { data });
        throw Error('API Error');
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = options => {
  return window
    .fetch(`${baseURL}/public-key`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log('API error:', { data });
        throw Error('API Error');
      } else {
        return data.publicKey;
      }
    });
};

const api = {
  getPaymentMethods,
  createPaymentMethod,
  deletePaymentMethod,
  createPaymentIntent,
  getPublicStripeKey,
  getDonationOption
};

export default api;
