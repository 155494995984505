import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import './css/Sidebar.css';
import ShareButton from './ShareButton';

export default function SidebarCover({
    className = '',
    image,
    badgeText
}) {

    return (
        <div className="sidebar-cover">
            <div
                className="sidebar-cover-image"
                style={{
                    backgroundImage: `url('${ image }')`
                }}
            >
            </div>
            <div className={ `sidebar-cover-after ${ className }` }>
                <Badge
                    pill
                    variant="light"
                    className="sidebar-cover-after-pill"
                >
                    { badgeText }
                </Badge>
                <ShareButton />
            </div>
        </div>
    )
}