import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Sidebar from './Sidebar';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';

import AppLoader from './AppLoader';
import CreateProjectForm from './CreateProjectForm';
import { api } from '../api';
import { addProject } from '../actions/projects';

export default function SidebarCreateProject({ show }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const appState = useSelector(state => state.app.appState);
  const [users, setUsers] = useState();
  const [ isFetchingUsers, setIsFetchingUsers ] = useState();
  const [ isSending, setIsSending ] = useState(false);
  const isFetching = useSelector(state => state.donationOptions.isFetching);
  const activeStatus = useSelector(state => state.ui.activeStatus);
  const token = localStorage.getItem('token');
  const formik = useFormik({
    initialValues: {
      projectTypeId: undefined,
      name: '',
      description: '',
      longDescription: '',
      location: '',
      lat: '',
      lng: '',
      donationOptions: [],
      projectUsers: []
    },
    validationSchema: Yup.object({
      projectTypeId: Yup.number()
        .required(t('errors.required')),
      name: Yup.string()
        .required(t('errors.required')),
      description: Yup.string()
        .max(255, t('errors.maxLength') + ' ${max}')
        .required(t('errors.required')),
      longDescription: Yup.string()
        .max(65535, t('errors.maxLength') + ' ${max}'),
      location: Yup.string()
        .required(t('errors.required')),
      lat: Yup.number()
        .min(-90, t('errors.numberMin') + " ${ min }")
        .max(90, t('errors.numberMax') + " ${ max }")
        .required(t('errors.required')),
      lng: Yup.string()
        .min(-180, t('errors.numberMin') + " ${ min }")
        .max(180, t('errors.numberMax') + " ${ max }")
        .required(t('errors.required')),
      donationOptions: Yup.array()
    }),
    onSubmit: async function handleSubmit(values, { resetForm }) {
      try {
        setIsSending(true);
        const response = await api.post('/projects', values);

        // A new project is always added as a pending project.
        // Therefore render the new project only if browsing pending projects.
        if (/^PENDING$/i.test(activeStatus)) {
          const { project } = response.data;
          console.log('created project', project);
          dispatch(addProject(project));
        }

        formik.resetForm();
        history.push('/');
      } catch(error) {
        console.error(error);
      } finally {
        setIsSending(false);
      }
    }
  });

  useEffect(() => {
    async function fetchUsers() {
      try {
        setIsFetchingUsers(true);
        const response = await api.get('/users');
        const { users } = response.data;
        setUsers(users);
        setIsFetchingUsers(false);
      } catch(error) {
        console.log(error);
      }
    }
    if (show) {
      fetchUsers();
    }
  }, [show]);

  if (show && !token) {
    return <Redirect to="/menu/login" />;
  }

  return (
    <Sidebar
      open={ appState !== 'PICK_LOCATION' && show }
      pullRight={true}
      onHide={ () => formik.resetForm() }
      headerOptions={{
        content:(
          <div className="d-flex justify-content-end">
            <Button onClick={ formik.handleSubmit }>
              <FontAwesomeIcon icon={ faPaperPlane } />
              <span className="ml-1">{ t('createProjectForm.sendProject') }</span>
            </Button>
          </div>
        ),
        reverse: true
      }}
      content={
        <>
          <AppLoader component={ true } active={ isFetching || isFetchingUsers || isSending } />
          <CreateProjectForm formik={ formik } users={ users } />
        </>
      }
    />
  );
}
