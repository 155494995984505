import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { updateFilters } from '../actions/projectFilters';
import { getVisibleProjects } from '../reducers/projects';
import Button from 'react-bootstrap/Button';

import ProjectCard from './ProjectCard';
import { useDebounce } from '../utils';
import './css/SidebarSearch.css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SidebarSearch({ show, onHide }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const projects = useSelector(state => state.projects.projects);
  const projectFilters = useSelector(state => state.projectFilters);
  const visibleProjects = getVisibleProjects(projects, projectFilters);
  const [ search, setSearch ] = useState('');
  const debouncedSearch = useDebounce(search, 200);

  function handleGoToProjectClick(projectId) {
    history.push(`/projects/${projectId}`);
  }

  useEffect(() => {
    dispatch(updateFilters({
      string: debouncedSearch
    }));
  }, [ debouncedSearch ])

  let sidebarContent = null;
  if (show) {
    sidebarContent = visibleProjects &&
    visibleProjects.map((project) => (
      <ProjectCard
        key={project.id}
        project={project}
        onClick={ () => handleGoToProjectClick(project.id) } />
    ));
  }

  return (
    <Sidebar
      open={ show }
      pullRight={true}
      headerOptions={{
        title: t('sidebarSearch.search'),
        reverse: true,
        content: (
          <InputGroup className="sidebar-search-input">
            <FormControl
              type="text"
              id="search"
              name="search"
              placeholder={t('sidebarSearch.search')}
              value={ search }
              onChange={({ target }) => setSearch(target.value) }
            />
            <InputGroup.Append>
              <Button
                onClick={ () => setSearch('') }
                variant="outline-secondary"
              >
                <FontAwesomeIcon size="lg" icon={ faTimes } />
              </Button>
            </InputGroup.Append>
          </InputGroup>

        )
      }}
      content={ sidebarContent }
    />
  );
}
