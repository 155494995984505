import * as tus from "tus-js-client";

function upload({
    file,
    uploadUrl,
    onSuccess,
    onError,
    onProgress
}) {
    var upload = new tus.Upload(file, {
        uploadUrl: uploadUrl,
        retryDelays: [0, 3000, 5000, 10000],
        onProgress: function(bytesUploaded, bytesTotal) {
            var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
            onProgress(percentage);
        },
        onError,
        onSuccess
    });
    upload.start();
    return upload;
}

function mockUpload({
    simulateError = false,
    onSuccess,
    onError,
    onProgress
}) {
    let start;

    function mockProgress(timestamp) {
        if (start === undefined) {
            start = timestamp;
        }
        const elapsed = timestamp - start;
        const progress = Math.min(elapsed/50, 100).toFixed(0);
        onProgress(progress);
        if (simulateError && progress > 40) {
            onError();
            return;
        }
        if (progress >= 100) {
            onSuccess();
            return;
        }
        window.requestAnimationFrame(mockProgress);
    }

    window.requestAnimationFrame(mockProgress);
}



export default {
    mockUpload,
    upload
}