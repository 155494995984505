import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';

export default function UserStatus({ user }) {
  return user ? (
    <div className="user-status">
      <FontAwesomeIcon
        className="mr-2"
        color="#297A0F"
        icon={ faUser }
      />
      { user &&
        <span>{ user.forename }</span>
      }
    </div>
  ) : (
    <div className="user-status">
      <FontAwesomeIcon
        color="#B81D5B"
        icon={ faUser }
      />
    </div>
  )
}
