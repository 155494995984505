import React from 'react';
import { useSelector } from 'react-redux';
import CountriesLayer from './data-layers/CountriesLayer';
import { colors } from './data-layers/colors';
import statistics from '../datasets/countries_datasheet_2.json';

const layers = [
    'childrenOutOfPrimarySchoolPercentage',
    'adultLiteracy',
    'undernourishmentPercentage',
    'temperatureRise',
    'forestArea'
]
export default function GlobeLayers({ onLoadCountries, onLoadActiveLayer }) {
    const visibleLayer = useSelector(state => state.globe.visibleLayer);
    const showCountries = useSelector(state => state.globe.showCountries);
    const appState = useSelector(state => state.app.appState);

    return (
        <>
            { layers.map(layer => (
                <CountriesLayer
                    key={ layer }
                    show={ appState === 'READY' && visibleLayer === layer }
                    data={ statistics }
                    name={ layer}
                    colors={ colors[layer].colors }
                    onLoad={ (dataSource) => console.log(dataSource.name) }
                />
            ))}
            <CountriesLayer
                name={ 'countries' }
                show={ showCountries }
                showBorders={ true }
                showLabels={ true }
                onLoad={ onLoadCountries }
            />
            <CountriesLayer
                name="activeCountry"
                fill={ true }
                show={ showCountries }
                showBorders={ false }
                showLabels={ false }
                onLoad={ onLoadActiveLayer }
            />
        </>
    );
}