import React, { useEffect, useState } from 'react';
import { gaEvent, gaEventTiming, gaSessionTiming } from '../utils';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { faExternalLinkAlt, faMinus, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';

import AttachmentPanel from './AttachmentPanel';
import Donate from './Donate';
import DonationOptionCard from './DonationOptionCard';
import ProjectUserCard from './ProjectUserCard';
import ShareButton from './ShareButton';
import SwipeableButton from './SwipeableButton';

import './css/SingleProject.css';

const MINIMUM = 500;
const STEP = 500;

export default function SingleProject({ project, onPaymentSuccess }) {
    const history = useHistory();
    const { t } = useTranslation();
    const user = useSelector(state => state.auth.user);
    const [showDonationInfo, setShowDonationInfo] = useState(window.localStorage.getItem('hasSeenDonationInfo') === 'true' ? false : true);
    const [donationAmounts, setDonationAmounts] = useState([]);
    const dismissDonationInfo = () => {
      window.localStorage.setItem('hasSeenDonationInfo', true);
      setShowDonationInfo(false);
    };
    const dismissLoginToDonate = (id) => {
        setDonationAmounts(donationAmounts.map((donationAmount) => {
            return donationAmount.id === id ? {
                ...donationAmount, showLoginToDonate: false
            } : donationAmount;
        }));
    };
    const decrementDonationAmount = (id, newAmount) => {
        setDonationAmounts(donationAmounts.map((donationAmount) => {
            const amount = newAmount - STEP;

            return donationAmount.id === id ? {
                ...donationAmount, amount
            } : donationAmount;
        }));
    };
    const incrementDonationAmount = (id, newAmount) => {
        setDonationAmounts(donationAmounts.map((donationAmount) => {
            const amount = newAmount + STEP;

            return donationAmount.id === id ? {
                ...donationAmount, amount
            } : donationAmount;
        }));
    };
    const handleEdit = (projectId) => {
        history.push(`/edit-project/${ projectId }`);
    };
    const handleButtonSwipe = (options) => {
      if (user) {
          setDonationAmounts(donationAmounts.map((donationAmount) => {
              return donationAmount.id === options.id ? {
                  ...donationAmount,
                  showDonateForm: true
              } : donationAmount;
          }));
      } else {
          setDonationAmounts(donationAmounts.map((donationAmount) => {
              return donationAmount.id === options.id ? {
                  ...donationAmount,
                  showLoginToDonate: true
              } : donationAmount;
          }));
      }
    };
    const openVideo = (roomName) => {
        gaEvent('user','jitsi', `${user.forename} ${user.surname}`);
        gaSessionTiming('user','jitsi', roomName);

        window.open(`https://meet.jit.si/${roomName}`, '_blank');
    };

    useEffect(() => {
        if (project) {
            setDonationAmounts(project.projectDonationOptions ? project.projectDonationOptions.map((donationOption) => {
                const id = donationOption.id;
                const amount = MINIMUM;

                return {
                    id,
                    amount,
                    showDonateForm: false,
                    showLoginToDonate: false
                };
            }) : []);
        }
    }, [project]);

    if (!project) {
        return null;
    }

    return (
        <div>
            <AttachmentPanel project={ project } />
            <div className="d-flex justify-content-between">
                <div>
                    <Badge className={ `align-self-start ${ project.type.toLowerCase() }` } pill>{project.type}</Badge>
                    <Badge className={ `align-self-start ml-1 ${ project.status.toLowerCase() }` } pill>{project.status}</Badge>
                </div>
                <div>
                    { user && (user.role === 'ADMIN' || project.projectUsers.some((projectUser) => projectUser.userId === user.id)) &&
                        <Button
                            className="mr-2"
                            variant="secondary"
                            onClick={() => handleEdit(project.id)}>
                            <FontAwesomeIcon icon={faPen} /> {t('singleProject.edit')}
                        </Button>
                    }
                    <ShareButton />
                </div>
            </div>
            <h2>{project.name}</h2>
            <h6>{project.description}</h6>
            <p>{project.longDescription}</p>
            { project.projectDonationOptions.length > 0 && showDonationInfo &&
                <Alert variant="primary" onClose={dismissDonationInfo} dismissible>
                    <h6>{t('donationInfo.heading')}</h6>
                    <p>{t('donationInfo.description')}</p>
                </Alert>
            }
            { project.projectDonationOptions.length > 0 && <h3>{t('createProjectForm.donationOptions')}</h3> }
            {
                project.projectDonationOptions && project.projectDonationOptions.map((donationOption) => {
                    let donationAmount = donationAmounts.find((donationAmount) => {
                        return donationAmount.id === donationOption.id;
                    });
                    const showDonateForm = donationAmount ? donationAmount.showDonateForm ? donationAmount.showDonateForm : false : false;
                    const showLoginToDonate = donationAmount ? donationAmount.showLoginToDonate ? donationAmount.showLoginToDonate : false : false;
                    donationAmount = donationAmount ? donationAmount.amount : 0;
                    donationAmount = donationOption.price - donationOption.amount < MINIMUM ? STEP : donationAmount;

                    return (
                        <>
                            <DonationOptionCard
                                key={donationOption.id}
                                donationOption={donationOption}
                                donationAmount={donationAmount} />
                            <div
                                key={`container-${donationOption.id}`}
                                className={`d-flex mb-${ showDonateForm || showLoginToDonate ? '2' : '3' }`}>
                                <Button
                                    key={`minus-${donationOption.id}`}
                                    className="align-self-center mr-3"
                                    disabled={donationOption.amount >= donationOption.price || (donationAmount - STEP < MINIMUM && donationAmount - STEP < donationOption.price - donationOption.amount)}
                                    onClick={() => decrementDonationAmount(donationOption.id, donationAmount)}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </Button>
                                { donationOption.amount < donationOption.price ?
                                    <SwipeableButton
                                        key={`swipe-button-${donationOption.id}`}
                                        activeText={t('singleProject.swipe')}
                                        text={t('singleProject.toGive', { amount: donationAmount / 100 })}
                                        onSwiped={() => handleButtonSwipe({ id: donationOption.id, donationAmount, projectDonationOptionId: donationOption.id })} /> :
                                    <div className="goal-filled">{t('singleProject.goalFilled')}</div> }
                                <Button
                                    key={`plus-${donationOption.id}`}
                                    className="align-self-center ml-3"
                                    disabled={donationAmount + STEP + donationOption.amount > donationOption.price}
                                    onClick={() => incrementDonationAmount(donationOption.id, donationAmount)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                            {
                                user && showDonateForm && <Donate
                                    amount={donationAmount}
                                    projectName={project.name}
                                    projectDonationOptionId={donationOption.id}
                                    onPaymentSuccess={onPaymentSuccess} />
                            }
                            {
                                !user && showLoginToDonate && <Alert variant="primary" onClose={() => dismissLoginToDonate(donationOption.id)} dismissible>
                                    <h6>{t('loginToDonate.heading')}</h6>
                                    <Trans
                                        i18nKey="loginToDonate.description"
                                        values={{ login: 'login', signup: 'signup' }}
                                        components={{ login: <Link to="/menu/login"></Link>, signup: <Link to="/menu/signup"></Link> }} />
                                </Alert>
                            }
                        </>
                    );
                })
            }
            { project.projectUsers.length > 0 && <h3>{t('singleProject.projectUsers')}</h3> }
            {
                project.projectUsers.map((user) => (
                    <ProjectUserCard
                        key={ user.id }
                        user={ user } />
                ))
            }
            { user &&
                <>
                    <h3>{t('singleProject.meetingHeading')}</h3>
                    { project.meetings.length > 0 ? project.meetings.map((meeting) => (
                        <p className="align-self-center">
                            { moment(meeting.from).format('D.M.YYYY HH:mm (Z)') } - { moment(meeting.to).format('D.M.YYYY HH:mm (Z)') }
                        </p>
                    )) : <p>{t('singleProject.noMeetings')}</p> }
                    <Button
                        variant="secondary"
                        onClick={() => openVideo(project.roomName)}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> { t('singleProject.joinMeeting') }
                    </Button>
                </>
            }
        </div>
    );
}
