import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';

import './css/Sidebar.css';

export default function SidebarHeader({
    search = false,
    favourite = false,
    backRoute,
    content,
    reverse = false,
    onHide
}) {
    const history = useHistory();

    return (
        <div
            className="sidebar-header"
            style={{
                flexDirection: reverse ? 'row-reverse' : 'row'
            }}
        >
            { backRoute &&
                <FontAwesomeIcon className="sidebar-header-icon" icon={ faAngleLeft } onClick={ () => history.push(backRoute) }/>
            }
            <div>
                { search &&
                    <FontAwesomeIcon className="sidebar-header-icon" icon={faSearch} onClick={() => history.push('/search')} />
                }
                { favourite &&
                    <FontAwesomeIcon className="sidebar-header-icon" icon={faStar} onClick={() => console.log('[ProjectSidebar] Should star the project')} />
                }
            </div>
            <div className="sidebar-header-center">
                { content }
            </div>
            <FontAwesomeIcon className="sidebar-header-icon" icon={ faTimes } onClick={ () => {
                if (onHide) {
                    onHide();
                }
                history.push('/');
            } }/>
        </div>
    )
}