import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_CLIENT,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    // UPDATE_PROFILE_SUCCESS,
    AUTH_REQUEST,
    AUTH_REQUEST_END,
    // CONFIRM_LOGOUT,
    // CANCEL_LOGOUT,
} from '../actions/actions'

const initialState = {
    isFetching: false,
    user: null,
    showConfirmLogout: false
};

export const auth = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case AUTH_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case AUTH_REQUEST_END: {
            return {
                ...state,
                isFetching: false
            };
        }
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS: {
            const { user } = action;
            return {
                ...state,
                isFetching: false,
                user
            };
        }
        // case CONFIRM_LOGOUT: {
        //     return {
        //         ...state,
        //         showConfirmLogout: true
        //     }
        // }
        // case CANCEL_LOGOUT: {
        //     return {
        //         ...state,
        //         showConfirmLogout: false
        //     }
        // }
        case SIGNUP_FAILURE:
        case LOGIN_FAILURE:
        case LOGOUT_CLIENT:
        {
            return initialState;
        }
        // case UPDATE_PROFILE_SUCCESS: {
        //     const { update } = action;
        //     const user = {
        //         ...state.user,
        //         ...update
        //     };
        //     return {
        //         ...state,
        //         user,
        //         isFetching: false
        //     };
        // }
        default: {
            return state;
        }
    }
}