import React from 'react';
import Badge from 'react-bootstrap/Badge';

import './css/ProjectCard.css';
import { api } from '../api';

const TAG = '[ProjectCard]';

export default function ProjectCard({
  actions,
  project,
  onClick = null
}) {
  let image = require(`../assets/images/countries/a_default_earth.jpg`);
  if (project.coverMedia) {
    if (project.coverMedia.vimeoId) {
      if (project.coverMedia.vimeoImgPath) {
        image = project.coverMedia.vimeoImgPath;
      }
    } else if (project.coverMedia.path) {
      image = `${ api.defaults.publicURL }/${ project.coverMedia.path }`;
    }
  }

  console.log(TAG, project);

  return (
    <div
      className={ `project-card ${ onClick && 'cursor-pointer' }` }
      onClick={ onClick }
    >
      <div className="project-card-cover" style={{
            backgroundImage: `url('${ image }')`
          }}>
          <Badge
            className={ `${ project.type.toLowerCase() }` }
            pill
          >
            { project.type }
          </Badge>
          <Badge
            className={ `${ project.status.toLowerCase() }` }
            pill
          >
            { project.status }
          </Badge>
      </div>
      { actions &&
        <div className="project-card-actions">
          { actions }
        </div>
      }
      <div className="project-card-body">
        <h4>{ project.name }</h4>
        <p>{ project.description }</p>
      </div>
    </div>
  );
}
