import React from 'react';
import { Route } from 'react-router-dom';

import SidebarMenu from './SidebarMenu';
import SidebarCountry from './SidebarCountry';
import SidebarCreateProject from './SidebarCreateProject';
import SidebarMyProjects from './SidebarMyProjects';
import SidebarPendingProjects from './SidebarPendingProjects';
import SidebarSearch from './SidebarSearch';
import SidebarProject from './SidebarProject';
import SidebarEditProject from './SidebarEditProject';

export default function Sidebars() {
    return (
        <>
            <Route
                path="/menu/:page?"
                children={ ({ match }) => {
                    return (
                        <SidebarMenu
                            show={ !!match }
                        />
                    );
                }}
            />
            <Route
                path="/search"
                children={ ({ match }) => {
                    return (
                        <SidebarSearch
                            show={ !!match }
                        />
                    );
                }}
            />
            <Route
                path="/country/:country"
                children={ ({ match }) => {
                    return (
                        <SidebarCountry
                            show={ !!match }
                        />
                    );
                }}
            />
            <Route
                path="/create-project"
                children={ ({ match }) => {
                    return (
                        <SidebarCreateProject
                            show={ !!match }
                        />
                    );
                }}
            />
            <Route
                path="/edit-project/:projectId"
                children={ ({ match }) => {
                    return (
                        <SidebarEditProject
                            show={ !!match }
                        />
                    );
                }}
            />
            <Route
                path="/my-projects"
                children={ ({ match }) => {
                    return (
                        <SidebarMyProjects
                            show={ !!match }
                        />
                    );
                }}
            />
            <Route
                path="/pending-projects"
                children={ ({ match }) => {
                    return (
                        <SidebarPendingProjects
                            show={ !!match }
                        />
                    );
                }}
            />
            <Route
                path="/projects/:projectId"
                children={ ({ match }) => {
                    return (
                        <SidebarProject
                            show={ !!match }
                        />
                    );
                }}
            />
        </>

    )
}
