import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ConfirmDeletePaymentMethod({ show, hide, onSubmit }) {
  const { t } = useTranslation();

  return (
    <Modal show={ show } onHide={ hide }>
      <Modal.Header closeButton>
        <Modal.Title>{ t('confirmDeletePaymentMethod.title') }</Modal.Title>
      </Modal.Header>
      <Modal.Body>{ t('confirmDeletePaymentMethod.description') }</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ hide }>
          { t('confirmDeletePaymentMethod.cancel') }
        </Button>
        <Button variant="danger" type="submit" onClick={ onSubmit }>
          { t('confirmDeletePaymentMethod.confirm') }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
