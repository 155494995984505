export const colors = {
    childrenOutOfPrimarySchoolPercentage: {
        unit: 37,
        colors: [
            {
                color: 'ca295e',
                limit: 52.5,
                label: '52.5 - 60.0'
            },
            {
                color: 'd54376',
                limit: 45
            },
            {
                color: 'de5a8d',
                limit: 37.5
            },
            {
                color: 'e670a4',
                limit: 30
            },
            {
                color: 'ed84ba',
                limit: 22.5
            },
            {
                color: 'f399ce',
                limit: 15
            },
            {
                color: 'f9ade2',
                limit: 7.5
            },
            {
                color: 'ffc1f4',
                limit: 0
            }
        ]
    },
    adultLiteracy: {
        unit: 37,
        colors: [
            {
                color: '0041A8',
                limit: 91,
                label: '91 - 100'
            },
            {
                color: '1E5CBC',
                limit: 81.9
            },
            {
                color: '3877CE',
                limit: 72.8
            },
            {
                color: '5291E0',
                limit: 63.7
            },
            {
                color: '6FADF0',
                limit: 54.6
            },
            {
                color: '8EC8FF',
                limit: 45.5
            },
            {
                color: 'A4D1FF',
                limit: 36.4
            },
            {
                color: 'B9DBFF',
                limit: 27.3
            },
            {
                color: 'CCE5FF',
                limit: 18.2
            },
            {
                color: 'DFEEFF',
                limit: 9.1
            },
            {
                color: 'F2F8FF',
                limit: 0
            }
        ]
    },
    undernourishmentPercentage: {
        unit: 37,
        colors: [
            {
                color: 'DB2C66',
                limit: 55,
                label: '55 - 61.5'
            },
            {
                color: 'E84E5B',
                limit: 49.5
            },
            {
                color: 'EF6D54',
                limit: 44
            },
            {
                color: 'F18A53',
                limit: 38.5
            },
            {
                color: 'F1A55A',
                limit: 33
            },
            {
                color:'EEBF69',
                limit: 27.5
            },
            {
                color: 'E2D182',
                limit: 22
            },
            {
                color: 'DAE09F',
                limit: 16.5
            },
            {
                color: 'D8EDBE',
                limit: 11
            },
            {
                color: 'DEF7DB',
                limit: 5.5
            },
            {
                color: 'EDFFF3',
                limit: 0
            }
        ]
    },
    temperatureRise: {
        unit: 8451,
        colors: [
            {
                color: 'DB2C66',
                limit: 2.75,
                label: '2.75 - 3.0'
            },
            {
                color: 'E74C63',
                limit: 2.5
            },
            {
                color: 'F06863',
                limit: 2.25
            },
            {
                color: 'F68166',
                limit: 2.0
            },
            {
                color: 'FB996E',
                limit: 1.75
            },
            {
                color: 'FFB17B',
                limit: 1.5
            },
            {
                color: 'FCC38D',
                limit: 1.25
            },
            {
                color: 'F9D3A3',
                limit: 1.0
            },
            {
                color: 'F8E2BC',
                limit: 0.75
            },
            {
                color: 'FAF0D7',
                limit: 0.5
            },
            {
                color: 'FFFDF2',
                limit: 0.25
            },
            {
                color: 'F2F8FF',
                limit: 0
            }
        ]
    },
    forestArea: {
        unit: 37,
        colors: [
            {
                color: '215B00',
                limit: 90,
                label: '90 - 100'
            },
            {
                color: '3B6E16',
                limit: 80
            },
            {
                color: '548129',
                limit: 70
            },
            {
                color: '6D943C',
                limit: 60
            },
            {
                color: '86A84E',
                limit: 50
            },
            {
                color: '9FBC62',
                limit: 40
            },
            {
                color: 'B8D176',
                limit: 30
            },
            {
                color: 'D2E68A',
                limit: 20
            },
            {
                color: 'ECFB9F',
                limit: 10
            },
            {
                color: 'FFFFFF',
                limit: 0
            }
        ]
    }
}