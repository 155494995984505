import { DONATION_OPTIONS_REQUEST_SUCCESS, DONATION_OPTIONS_REQUEST_FAILURE, DONATION_OPTIONS_REQUEST } from '../actions/actions';

const initialState = {
    isFetching: false
};

export function donationOptions(state = initialState, action) {
    switch(action.type) {
        case DONATION_OPTIONS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case DONATION_OPTIONS_REQUEST_SUCCESS: {
            const { donationOptions } = action;
            return {
                ...state,
                donationOptions,
                isFetching: false
            };
        }
        case DONATION_OPTIONS_REQUEST_FAILURE: {
            return {
                ...state,
                isFetching: false
            }
        }
        default: {
            return state;
        }
    }
}
