import React from 'react';
import { range } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Accordion, Button, Alert } from 'react-bootstrap';
import './css/FAQ.css';

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <div className="faq-content">
      <h3 className="mb-3">{t('faq.title')}</h3>

      <div>
          <ol>
            { range(0, 13, 1).map((n) => {
              return (
                <li className="mb-5">
                  <Accordion>
                    <div className="faq-question">
                      { t(`faq.faq-${ n }`) }
                      <div className="faq-toggle mt-3">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={ n + 1 }
                        >
                          { t('faq.showMore') }
                        </Accordion.Toggle>
                      </div>
                    </div>
                    <Accordion.Collapse eventKey={ n + 1 }>
                      <div className="mt-3">
                        <Alert variant="success">{ t(`faq.faq-${ n }-content`) }</Alert>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </li>
              );
            })}
          </ol>
      </div>
    </div>
  );
}
