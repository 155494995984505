import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { setAppState } from '../actions/app';
import { setSelectedLocation } from '../actions/globe';
import { useEffect } from 'react';
import {
  handleCanvasMouseDown as handleMouseDown,
  resolveClickLocation as resolveLocation,
} from '../globeUtils';
import { useRef } from 'react';
import { useMemo } from 'react';

export function LocationPicker({
    viewerRef
}) {
    const dispatch = useDispatch();
    const appState = useSelector(state => state.app.appState);
    const selectedLocation = useSelector(state => state.globe.selectedLocation);
    const onMouseDownLocation = useRef(null);

    const handleCanvasMouseDown = useMemo(() => handleMouseDown.bind(onMouseDownLocation), [ onMouseDownLocation ]);
    const resolveClickLocation = useMemo(() => resolveLocation.bind({
      onMouseDownLocation,
      viewerRef
    }), [onMouseDownLocation, viewerRef]);

    const handleCanvasClick = useCallback((e) => {
        console.count('handleCanvasClick')
        const location = resolveClickLocation(e);
        if (!location) {
        return;
        }
        dispatch(setSelectedLocation(location));
    }, [dispatch, resolveClickLocation])

    useEffect(() => {
        const { canvas } = viewerRef.current.cesiumElement;
        if (appState === 'PICK_LOCATION') {
            // Listen to both mousedown and pointerdown to make this work across browsers.
            // This will trigger two events on some browsers (e.g. Firefox), but we're only setting a single variable in the callback, so we don't mind.
            canvas.addEventListener('pointerdown', handleCanvasMouseDown, false);
            canvas.addEventListener('mousedown', handleCanvasMouseDown, false);
            canvas.addEventListener('click', handleCanvasClick, false);
            console.log('ADDED canvas listeners');
        } else {
            canvas.removeEventListener('click', handleCanvasClick);
            canvas.removeEventListener('pointerdown', handleCanvasMouseDown);
            canvas.removeEventListener('mousedown', handleCanvasMouseDown);
        }
    }, [appState, handleCanvasClick, handleCanvasMouseDown, viewerRef]);

    if (appState !== 'PICK_LOCATION') {
        return null;
    }

    return (
        <Alert variant="secondary" className="pick-location-info">
            { selectedLocation ?
                <>
                    Please confirm selected location
                    <div>
                    <Button variant="primary" onClick={ () => {
                        dispatch(setAppState('READY'));
                        dispatch(setSelectedLocation(null));
                    }}>Confirm</Button>
                    <Button variant="secondary" onClick={ () => dispatch(setSelectedLocation(null)) }>Cancel</Button>
                    </div>
                </> :
                <>
                    Please pick a location from the map to update project coordinates.
                    <div>
                    <Button variant="secondary" onClick={ () => dispatch(setAppState('READY')) }>Cancel</Button>
                    </div>
                </>
            }
        </Alert>
    )
}