import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { api } from '../api';
import { deleteProject } from '../actions/projects';

export default function ConfirmDeleteProject({ show, hide, project }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  async function handleDelete(projectId) {
    try {
      await api.delete(`/projects/${ project.id }`);
      dispatch(deleteProject(project.id));
      hide();
      history.push('/');
    } catch(error) {
      console.error(error);
    }
  }

  return (
    <Modal show={ show } onHide={ hide }>
      <Modal.Header closeButton>
        <Modal.Title>{ t('confirmDeleteProject.title') }</Modal.Title>
      </Modal.Header>
      <Modal.Body>{ t('confirmDeleteProject.description') }</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ hide }>
          { t('confirmDeleteProject.cancel') }
        </Button>
        <Button variant="danger" type="submit" onClick={handleDelete}>
          { t('confirmDeleteProject.confirm') }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
