import {
  PROJECTS_REQUEST,
  PROJECTS_REQUEST_SUCCESS,
  PROJECTS_REQUEST_FAILURE,
  MY_PROJECTS_REQUEST,
  MY_PROJECTS_REQUEST_SUCCESS,
  MY_PROJECTS_REQUEST_FAILURE,
  SINGLE_PROJECT_REQUEST,
  SINGLE_PROJECT_REQUEST_SUCCESS,
  SINGLE_PROJECT_REQUEST_FAILURE,
  CLEAR_SINGLE_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT
} from './actions';
import { api } from '../api';
import { setActiveStatus } from './ui';

// Get all projects (optionally by status)
export function fetchProjects(status) {
    return async dispatch => {
        const url = `projects/${ status ? status.toLowerCase() : '' }`;
        dispatch(onFetchProjects());
        try {
            const response = await api.get(url);
            const { projects } = response.data;
            dispatch(onFetchProjectsSuccess(projects));
            dispatch(setActiveStatus(status));
        } catch(error) {
            dispatch(onFetchProjectsFailure(error));
        }
    };
}

// Get all projects the user is a part of
export function fetchMyProjects() {
    return async dispatch => {
        const url = `users/me/projects`;
        dispatch(onFetchMyProjects());
        try {
            const response = await api.get(url);
            const myProjects = response.data.projects;
            dispatch(onFetchMyProjectsSuccess(myProjects));
        } catch(error) {
            dispatch(onFetchMyProjectsFailure(error));
        }
    };
}

// Get a single project
export function fetchProjectById(projectId) {
    return async dispatch => {
        const url = `projects/${projectId}`;
        dispatch(onFetchProjectById());
        try {
            const response = await api.get(url);
            const { project } = response.data;
            dispatch(onFetchProjectByIdSuccess(project));
        } catch(error) {
            dispatch(onFetchProjectByIdFailure(error));
        }
    };
}

export function addProject(project) {
    return {
        type: ADD_PROJECT,
        project
    };
}

export function updateProject(project) {
    return {
        type: UPDATE_PROJECT,
        project
    };
}

export function deleteProject(projectId) {
    return {
        type: DELETE_PROJECT,
        projectId
    };
}

export function clearProject() {
    return {
        type: CLEAR_SINGLE_PROJECT
    };
}

function onFetchProjects() {
    console.log('[onFetchProjects]');
    return {
        type: PROJECTS_REQUEST
    };
}

function onFetchProjectsSuccess(projects) {
    console.log('[onFetchProjectsSuccess]', projects);
    return {
        type: PROJECTS_REQUEST_SUCCESS,
        projects
    };
}

function onFetchProjectsFailure(error) {
    console.log('[onFetchProjectsFailure]', error);
    return {
        type: PROJECTS_REQUEST_FAILURE,
        error
    };
}

function onFetchMyProjects() {
    console.log('[onFetchMyProjects]');
    return {
        type: MY_PROJECTS_REQUEST
    };
}

function onFetchMyProjectsSuccess(myProjects) {
    console.log('[onFetchMyProjectsSuccess]', myProjects);
    return {
        type: MY_PROJECTS_REQUEST_SUCCESS,
        myProjects
    };
}

function onFetchMyProjectsFailure(error) {
    console.log('[onFetchMyProjectsFailure]', error);
    return {
        type: MY_PROJECTS_REQUEST_FAILURE,
        error
    };
}

function onFetchProjectById() {
    console.log('[onFetchProjectById]');
    return {
        type: SINGLE_PROJECT_REQUEST
    };
}

function onFetchProjectByIdSuccess(project) {
    console.log('[onFetchProjectByIdSuccess]', project);
    return {
        type: SINGLE_PROJECT_REQUEST_SUCCESS,
        project
    };
}

function onFetchProjectByIdFailure(error) {
    console.log('[onFetchProjectByIdFailure]', error);
    return {
        type: SINGLE_PROJECT_REQUEST_FAILURE,
        error
    };
}
