import React from 'react';
import {
    GeoJsonDataSource
} from 'resium';
import {
    Color,
    ConstantProperty,
    Ellipsoid,
    HorizontalOrigin,
    LabelGraphics,
    LabelStyle,
    BoundingRectangle
} from 'cesium';
import { map, uniqBy, sortBy } from 'lodash';
import countriesGeoJSON from '../../datasets/lower_poly_countries.json';
import { getCentroid, getRGBByValue, parseNum } from '../../utils';

const transparent = new Color(1, 1, 1, 0.01);

export default function CountriesLayer({
    colors,
    data,
    name,
    show,
    showBorders = false,
    showLabels = false,
    onLoad
}) {

    function handleLoadDataSource(dataSource) {
        const entities = dataSource.entities.values;
        entities.forEach(entity => {
            entity.polygon.height = 0;
            entity.polygon.outline = showBorders;
            entity.polygon.fill = true;
            if (data && colors && name) {
                loadColor(entity);
            }
        });
        if (showLabels) {
            loadLabels(entities);
        }
        onLoad(dataSource);
    }

    function loadColor(entity) {
        const countryData = data.find(item => {
            return item.name.trim().toLowerCase() === entity.name.trim().toLowerCase();
        });
        if (countryData) {
            let value = countryData[name];
            if (value && typeof value === 'string') {
                value = parseNum(value);
                value = parseFloat(value);
            }
            if (value) {
                let color = getRGBByValue(value, colors);
                entity.polygon.material = new Color(...color, 0.8);
                entity.polygon.fill = true;
            }
        }
    }

    function loadLabels(entities) {
        const uniqueCountries = map(uniqBy(entities, 'name'), 'name');
        for (let countryName of uniqueCountries) {
            const countryEntities = entities.filter(entity => entity.name.trim().toLowerCase() === countryName.trim().toLowerCase());
            const entitiesBySize = sortBy(countryEntities, [function(item) {
                const positions = item.polygon.hierarchy.valueOf().positions;
                const boundingRectangle = BoundingRectangle.fromPoints(positions);
                item.boundingRectangle = boundingRectangle;
                return (1 - boundingRectangle.width * boundingRectangle.height);
            }]);
            const countryMainEntity = entitiesBySize[0];
            let center = getCentroid(countryMainEntity.polygon.hierarchy.valueOf().positions);
            Ellipsoid.WGS84.scaleToGeodeticSurface(center, center);
            countryMainEntity.position = center;
            const label = new LabelGraphics();
            label.text = new ConstantProperty(countryName.toUpperCase());
            label.font = new ConstantProperty('20pt');
            label.horizontalOrigin = HorizontalOrigin.CENTER;
            label.fillColor = new ConstantProperty(Color.WHITE);
            label.outlineColor = new ConstantProperty(Color.BLACK);
            label.outlineWidth = new ConstantProperty(2);
            label.style = new ConstantProperty(LabelStyle.FILL_AND_OUTLINE);
            countryMainEntity.label = label;
        }
    }

    return  (
        <GeoJsonDataSource
            name={ name }
            show={ show }
            clampToGround={ true }
            data={ countriesGeoJSON }
            fill={ transparent }
            stroke={ Color.BLACK }
            strokeWidth={ 1 }
            onLoad={ handleLoadDataSource }
        />
    );
}