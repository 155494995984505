import axios from 'axios';
import { stripTrailingSlashes } from './utils';

export const api = axios.create({
    baseURL: stripTrailingSlashes(process.env.REACT_APP_API_URL),
    publicURL: stripTrailingSlashes(process.env.REACT_APP_PUBLIC_URL)
});

api.interceptors.request.use(function (config) {
    config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${ localStorage.getItem('token') }`
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use(function(response) {
    return response;
}, async function(error) {
    if (
        error.request.responseType === 'blob'
        && error.response.data instanceof Blob
        && error.response.data.type
        && error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
        error = await new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                error.response.data = JSON.parse(reader.result);
                console.log(error.response.data);
                resolve(error);
            };
            reader.onerror = () => {
                reject(error);
            };
            reader.readAsText(error.response.data);
        });
    };

    if (error.response.data && error.response.data.message) {
        return Promise.reject(error);
    }

    return Promise.reject(error);
});
