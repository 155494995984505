import {
    LOGIN_SUCCESS,
    LOGOUT_CLIENT,
    SIGNUP_SUCCESS,
    UPDATE_PROFILE_SUCCESS,
    AUTH_REQUEST,
    AUTH_REQUEST_END,
    SIGNUP_FAILURE,
    LOGIN_FAILURE,
    // CONFIRM_LOGOUT,
    // CANCEL_LOGOUT,
} from './actions';
import { api } from '../api';
import { wait } from '../utils';
import ReactGA from 'react-ga';
import { gaEvent, gaEventTiming, gaSessionTiming } from '../utils';

export function login(credentials) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const response = await api.post('/login', credentials);
            localStorage.setItem('token', response.data.token);
            dispatch(onLoginSuccess(response.data));
        } catch(error) {
            dispatch(onLoginFailure(error));
            throw error;
        }
    }
}

function onLoginSuccess(data) {
    const {user} = data;

    if ( user ) {
	gaEvent('user','login',user.forename + " " + user.surname);	
	gaSessionTiming('user','login',user.forename + " " + user.surname);
    }	
    
    return {
        type: LOGIN_SUCCESS,
        user
    };
}

function onLoginFailure(error) {
    return {
        type: LOGIN_FAILURE,
        error
    }
}

export function signup(userData) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const response = await api.post('/users', userData);
            localStorage.setItem('token', response.data.token);
            dispatch(onSignupSuccess(response.data));
        } catch(error) {
            dispatch(onSignupFailure(error));
            throw error;
        }
    }
}

function onSignupSuccess(data) {
    const {user} = data;
    if ( user ) {
	gaEvent('user','signup',user.forename + " " + user.surname);	
	gaSessionTiming('user','signup',user.forename + " " + user.surname);
    }	

    return {
        type: SIGNUP_SUCCESS,
        user
    };
}

function onSignupFailure(error) {
    return {
        type: SIGNUP_FAILURE,
        error
    }
}

export function logout(allDevices = false) {

    gaEvent('user','logout','logout');	
    gaSessionTiming('user','logout','logout');	

    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            const uri = allDevices ? '/logout-all' : '/logout';
            await api.post(uri, {});
            localStorage.removeItem('token');
            dispatch(logoutClient());
        } catch(error) {
            console.log(error);
            dispatch(onAuthError(error));
        }
    };
}

function onAuthRequest() {
    return {
        type: AUTH_REQUEST
    }
}

function onAuthSuccess() {
    return {
        type: AUTH_REQUEST_END
    }
}


function onAuthError(error) {
    return {
        type: AUTH_REQUEST_END,
        error
    }
}

export function getMe() {
    return async dispatch => {
        dispatch(onAuthRequest());
        if (localStorage.getItem('token')) {
            try {
                const response = await api.get('/users/me');;
                dispatch(onLoginSuccess(response.data));
            } catch(error) {
                localStorage.removeItem('token');
                dispatch(onLoginFailure(error));
            }
        } else {
            dispatch(onLoginFailure());
        }
    }
}

// export function confirmLogout() {
//     return {
//         type: CONFIRM_LOGOUT
//     }
// }

// export function cancelLogout() {
//     return {
//         type: CANCEL_LOGOUT
//     }
// }

export function logoutClient() {
    return {
        type: LOGOUT_CLIENT
    };
}

export function updateProfile(update) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await api.put(`/users/me`, update);
            dispatch(onUpdateProfileSuccess(update));
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

function onUpdateProfileSuccess(update) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        update
    };
}

export function updatePassword(update) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await api.put(`/users/me/password`, update);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function initPasswordReset(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await api.post(`/password-reset`, data);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function validateResetToken(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await api.get(`/reset-password/${data.id}/${data.token}`);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function resetPassword(data) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await api.post(`/reset-password`, data);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function deleteCustomerProfile(id) {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await api.del(`/users/${ id }`, true);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}

export function deleteProfile() {
    return async dispatch => {
        dispatch(onAuthRequest());
        try {
            await api.del(`/users/me`, true);
            dispatch(onAuthSuccess());
        } catch(error) {
            dispatch(onAuthError(error));
            throw new Error();
        }
    }
}
