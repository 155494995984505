import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';

import { api } from '../api';

import './css/DonationOptionCard.css';

export default function DonationOptionCard({ donationOption, donationAmount, showProgress = true }) {
  const { t } = useTranslation();
  return (
    <Card
      className="donation-option-card vertical-card"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${api.defaults.publicURL}/${donationOption.image}") center / cover no-repeat`
      }}>
      <div className="vertical-card-left">
        {
          donationOption.companyLogo && <img
            className="donation-option-company-logo"
            alt={`${donationOption.companyName} logo`}
            src={`${api.defaults.publicURL}/${donationOption.companyLogo}`}
          />
        }
      </div>
      <Card.Body>
        <Card.Title>{ donationOption.name }</Card.Title>
        <Card.Text>
          { donationOption.description }
          { showProgress &&
            <>
              <Row className="justify-content-between" noGutters>
                <span>{donationOption.amount / 100} €</span>
                <span>{donationAmount / 100} €</span>
                <span>{t('donationOptionCard.goal')}: {donationOption.price / 100} €</span>
              </Row>
               { donationOption.amount >= donationOption.price ?
                 <ProgressBar
                   variant="primary"
                   max={donationOption.price}
                   now={donationOption.amount}
                   label={`${donationAmount / 100} €`}
                   srOnly /> :
                 <ProgressBar>
                    <ProgressBar
                      variant="primary"
                      max={donationOption.price}
                      now={donationOption.amount}
                      label={`${donationAmount / 100} €`}
                      srOnly />
                    <ProgressBar
                      variant="warning"
                      max={donationOption.price}
                      now={donationAmount}
                      label={`${donationAmount / 100} €`}
                      srOnly />
                  </ProgressBar>
                }
            </>
          }
        </Card.Text>
      </Card.Body>
    </Card>);
}
