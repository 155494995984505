import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { gaEvent, gaEventTiming, gaSessionTiming, trackingVars } from '../utils';
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import SidebarHeader from './SidebarHeader';
import PieChartResponsive from './PieChartResponsive';
import { Link, useParams } from 'react-router-dom';
import statistics from '../datasets/countries_datasheet_2.json';
import SidebarCover from './SidebarCover';

import './css/SidebarCountry.css';
import AppLoader from './AppLoader';
import { Spinner } from 'react-bootstrap';

export default function SidebarCountry({ show }) {
  const { country } = useParams();

  let data;
  if (country) {
    data = statistics.find(item => item.name.trim().toLowerCase() === country.trim().toLowerCase());
  }

  useEffect(() => {
  }, [show]);

  if ( data ) {
      gaEvent('user','countryview',trackingVars.countryName);
      gaEventTiming('user','countryview',trackingVars.countryName);
      if (data.name) trackingVars.countryName = data.name;
  }

  return (
    <Sidebar
      open={ show }
      pullRight={ true }
      headerOptions={{
        content: data ? <h4 style={{ fontWeight: '400' }}>{ data.name }</h4> : '',
        reverse: true
      }}
      content={ country ? <SingleCountry data={ data } /> : null }
    />
  );
}

function SingleCountry({ data }) {
  const { t } = useTranslation();
  const [extract, setExtract] = useState();
  const [pageId, setPageId] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [wikipediaMessage, setWikipediaMessage] = useState('');

  useEffect(() => {
    async function fetchWikipediaExtract(country) {
      setIsFetching(true);
      const url = `https://en.wikipedia.org/w/api.php?action=query&prop=extracts&exsentences=10&exlimit=1&titles=${ country }&explaintext=1&formatversion=2&format=json&origin=*`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      const { extract, pageid } = data.query.pages[0];
      setExtract(extract);
      setPageId(pageid);
      setIsFetching(false);
    }

    try {
      fetchWikipediaExtract(data.name);
    } catch(error) {
      setWikipediaMessage('No Wikipedia extract available.');
    }
  }, [data]);

  if (!data) {
    return <Alert variant="warning">There seems to be no data available for { data.name }</Alert>;
  }

  let image = require(`../assets/images/countries/a_default_earth.jpg`);
  if (data.image) {
    image = require(`../assets/images/countries/${ data.image }`);
  }

  let displayDataSections = [
    [
      'population',
      'populationRank'
    ],
    [
      'urbanPercentage',
      'ruralPercentage'
    ],
    [
      'GDP2017FAOStat',
      'GDP2017PerCapita'
    ],
    [
      'adultLiteracy',
      'childrenOutOfPrimarySchoolPercentage'
    ],
    [
      'undernourishmentPercentage'
    ],
    [
      'forestArea',
      'temperatureRise'
    ]
  ];

  displayDataSections = displayDataSections.map((section) => {
    return section.map((item) => {
      return (
        <div className="country-sidebar-stats-item">
          <span>{ t(`dataLabels.${ item }`) }</span>
          <span>{ data[item] } { ['forestArea', 'adultLiteracy'].includes(item) && '%' }{ item === 'temperatureRise' && '\u2103' }</span>
        </div>
      );
    });
  });


  return (
    <>
      <SidebarCover badgeText="Country" className="country" image={ image } />
      <h3 className="mt-2 mb-2">{ data.name }</h3>

      { displayDataSections.map(items => {
        return (
          <div className="country-sidebar-stats-section">
            { items }
          </div>
        );
      })}

      <div className="country-sidebar-wikipedia">
        <div className="country-sidebar-wikipedia-heading">
          <span style={{ marginRight: '1rem' }}>Wikipedia</span>
          <Button
            disabled={ !pageId }
            variant="link"
            as="a"
            href={`https://en.wikipedia.org/?curid=${ pageId }`}
            target="_blank"
          >
            <FontAwesomeIcon icon={ faExternalLinkAlt } />
          </Button>
        </div>
        <div className="country-sidebar-wikipedia-content">
          { isFetching &&
            <div className="country-sidebar-spinner">
              <Spinner
                  animation="border"
                  role="status"
                  variant="secondary"
              >
                  <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          }
          <div className="country-sidebar-wikipedia-extract">
            { extract && stripWikipediaExtract(extract).split('\n').map((item, index) => {
              return <p key={ `extract-${ index }`}>{ item }</p>;
            }) }
          </div>
          { wikipediaMessage && <Alert variant="secondary">{ wikipediaMessage }</Alert>}
        </div>
      </div>
    </>
  )
}

function stripWikipediaExtract(extract) {
  return extract.replace(/(\(listen\)|\(listen to all\))/gm, '');
}
