import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import AppLoader from './AppLoader';
import Button from 'react-bootstrap/Button';

import { updatePassword } from '../actions/auth';
import { useHistory } from 'react-router-dom';

export default function LoginForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isFetching = useSelector(state => state.auth.isFetching);
  const [saveError, setSaveError] = useState();

  const formik = useFormik({
    initialValues: {
        previousPassword: '',
        password: '',
        confirmPassword: ''
    },
    validationSchema: Yup.object({
        previousPassword: Yup.string()
            .required(t('errors.required')),
        password: Yup.string()
            .required(t('errors.required')),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], t('errors.confirmPassword'))
    }),
    onSubmit: async function handleSubmit(values, { resetForm }) {
      try {
        values = omit(values, 'confirmPassword');
        await dispatch(updatePassword(values));
        history.push('/menu');
      } catch(error) {
        setSaveError(t('errors.generalError'));
      }
    }
  });

  return (
    <>
      <AppLoader
        component={ true }
        active={ isFetching }
      />
      <Form
        className="change-password-form"
        noValidate
        onSubmit={ formik.handleSubmit }
      >
          <Form.Group>
            <Form.Label>{t('changePasswordForm.previousPassword')}</Form.Label>
            <Form.Control
                id="previousPassword"
                name="previousPassword"
                type="password"
                placeholder={t('changePasswordForm.previousPassword') }
                value={ formik.values.previousPassword }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.previousPassword && formik.errors.previousPassword }
            />
          {formik.touched.previousPassword && formik.errors.previousPassword ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.previousPassword }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('changePasswordForm.password')}</Form.Label>
            <Form.Control
                id="password"
                name="password"
                type="password"
                placeholder={t('changePasswordForm.password') }
                value={ formik.values.password }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.password && formik.errors.password }
            />
            {formik.touched.password && formik.errors.password ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.password }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('changePasswordForm.confirmPassword')}</Form.Label>
            <Form.Control
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder={t('changePasswordForm.confirmPassword') }
                value={ formik.values.confirmPassword }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.confirmPassword && formik.errors.confirmPassword }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.confirmPassword }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        <Button
          variant="primary"
          block
          type="submit"
        >
          {t('changePasswordForm.save')}
        </Button>
        { saveError &&
          <Alert className="mt-3" variant="danger">{ saveError }</Alert>
        }
      </Form>
    </>
    );
}
