import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import './css/SplashScreen.css';
import { setAppState } from '../actions/app';

export default function SplashScreen({
    active = false,
    ready = false
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function handlePlay() {
        dispatch(setAppState('INTRO'));
    }

    return (
        <div className={ `splash-screen ${ active ? 'active' : '' }` }>
            { active &&
                <div className="splash-screen-content">
                    <div className="splash-screen-action">
                        <embed
                            className="tutorial-video"
                            src="https://player.vimeo.com/video/542547769?badge=0&amp;autopause=0&amp;autoplay=1&amp;loop=1&amp;player_id=0&amp;app_id=58479"
                            frameborder="0">
                        </embed>

                        { ready ?
                            (
                                <Button
                                    className="splash-screen-button-close"
                                    variant="primary"
                                    size="lg"
                                    onClick={ handlePlay }
                                >
                                    { t('splashScreen.explore') }
                                </Button>
                            ) : (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    variant="light"
                                >
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            )
                        }
                    </div>

                    <p className="cookie-notice">
                        { t('splashScreen.cookieNotice') }
                    </p>
                </div>
            }
        </div>
    );
};
