import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import DonationOptionCard from './DonationOptionCard';
import './css/DonationOptionSelector.css';
import { Alert } from 'react-bootstrap';
import { api } from '../api';

export default function DonationOptionSelector({
    name,
    error,
    donationOptions,
    value,
    onChange,
    isInvalid,
    readOnly = false
}) {
    const [projectDonationOptions, setProjectDonationOptions] = useState([]);
    const { projectId } = useParams();

    useEffect(() => {
        if (projectId) {
            async function getProjectDonationOptions() {
                const response = await api.get(`/projects/${projectId}/donation-options`);
                const { projectDonationOptions } = response.data;
                setProjectDonationOptions(projectDonationOptions);
            }
            getProjectDonationOptions();
        }
    }, [projectId]);

    function hasDonations(id) {
        const projectDonationOption = projectDonationOptions.find((projectDonationOption) => {
            return projectDonationOption.donationOptionId === id;
        });
        return projectDonationOption ? projectDonationOption.amount > 0 : false;
    }

    function handleSelectDonationOption(id) {
        if (value.includes(id)) {
            const update = value.filter(option => option !== id);
            onChange({
                name,
                value: update
            });
        } else {
            onChange({
                name,
                value: [
                    ...value,
                    id
                ]
            });
        }
    }

    return (
        <div className={ `donation-options-selector` } >
            { isInvalid && error &&
                <Alert className="mb-2" variant="danger">{ error }</Alert>
            }
            {
                donationOptions.map((donationOption) => {
                const isSelected = value.includes(donationOption.id);
                return (
                    <div
                    className={ `donation-options-item ${ isSelected ? 'selected' : '' }${ error ? ' is-invalid ' : '' }` }
                    onClick={ () => {
                        if (readOnly) {
                            return;
                        }
                        if (hasDonations(donationOption.id)) {
                            return;
                        }
                        handleSelectDonationOption(donationOption.id);
                    }}
                    >
                    <FontAwesomeIcon className="donation-options-item-checkbox" icon={ isSelected ? faCheckSquare : faSquare } />
                    <DonationOptionCard
                        key={donationOption.id}
                        donationOption={donationOption}
                        showProgress={ false }
                    />
                    </div>
                );
                })
            }
        </div>
    )
}
