import React, { useEffect } from 'react';

import './MenuMobile.css';

export default function MenuMobile({ active, onHide, children }) {
    useEffect(() => {
        console.log('[MenuMobile] active updated', active);
    }, [ active ]);

    useEffect(() => {
        if (active) {
            window.addEventListener('click', onHide);
        } else {
            window.removeEventListener('click', onHide);
        }

        return function cleanup() {
            window.removeEventListener('click', onHide);
        }
    }, [ active, onHide ]);

    return (
        <div
            className={ `flex-column menu-mobile-wrapper${active ? ' active' : ''}` }
        >
            { children }
        </div>
    )
}
