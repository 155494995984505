import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ProgressBar, Alert } from 'react-bootstrap';

export function VideoUploadModal({
    error,
    progress,
    show,
    complete,
    onHide,
    onPause,
    onAbort,
    onResume
}) {
    const [ isPaused, setIsPaused ] = useState(false);

    useEffect(() => {
        setIsPaused(false);
    }, [show]);

    function handleStop() {
        setIsPaused(true);
        onPause();
    }

    function handleRestart() {
        setIsPaused(false);
        onResume();
    }

    let content = null;
    let action = null;
    if (error) {
        content = <Alert className="mt-2" variant="danger">There was an error uploading the video</Alert>;
        action = (
            <Button
                variant="primary"
                onClick={ onHide }>
                Close
            </Button>
        );
    } else if (isPaused) {
        content = <ProgressBar variant="info" now={ progress } />;
        action = (
            <>
                <Button
                    variant="success"
                    onClick={ handleRestart }>
                    Restart
                </Button>
                <Button
                    variant="danger"
                    onClick={ onAbort }>
                    Abort
                </Button>
            </>
        );
    } else if (complete) {
        content = <Alert className="mt-2" variant="success">The video was uploaded successfully. Please be patient while we process the video and create the cover picture.</Alert>;
        action = (
            <Button
                variant="primary"
                onClick={ onHide }>
                Finish
            </Button>
        );
    } else if (progress === 0) {
        content = <p>Starting video upload...</p>;
    } else {
        content = <ProgressBar animated variant="success" now={ progress } />;
        action = (
            <Button
                variant="danger"
                onClick={ handleStop }>
                Cancel
            </Button>
        );
    }

    return (
        <Modal
            show={ show }
            onHide={ onHide }
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>Upload video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { content }
            </Modal.Body>
            <Modal.Footer>
                { action }
            </Modal.Footer>
        </Modal>
      );
}
