export const COMPANIES_REQUEST = 'COMPANIES_REQUEST';
export const COMPANIES_REQUEST_SUCCESS = 'COMPANIES_REQUEST_SUCCESS';
export const COMPANIES_REQUEST_FAILURE = 'COMPANIES_REQUEST_FAILURE';
export const DONATION_OPTIONS_REQUEST = 'DONATION_OPTIONS_REQUEST';
export const DONATION_OPTIONS_REQUEST_SUCCESS = 'DONATION_OPTIONS_REQUEST_SUCCESS';
export const DONATION_OPTIONS_REQUEST_FAILURE = 'DONATION_OPTIONS_REQUEST_FAILURE';
export const PROJECTS_REQUEST = 'PROJECTS_REQUEST';
export const PROJECTS_REQUEST_SUCCESS = 'PROJECTS_REQUEST_SUCCESS';
export const PROJECTS_REQUEST_FAILURE = 'PROJECTS_REQUEST_FAILURE';
export const SINGLE_PROJECT_REQUEST = 'SINGLE_PROJECT_REQUEST';
export const SINGLE_PROJECT_REQUEST_SUCCESS = 'SINGLE_PROJECT_REQUEST_SUCCESS';
export const SINGLE_PROJECT_REQUEST_FAILURE = 'SINGLE_PROJECT_REQUEST_FAILURE';
export const MY_PROJECTS_REQUEST = 'MY_PROJECTS_REQUEST';
export const MY_PROJECTS_REQUEST_SUCCESS = 'MY_PROJECTS_REQUEST_SUCCESS';
export const MY_PROJECTS_REQUEST_FAILURE = 'MY_PROJECTS_REQUEST_FAILURE';
export const PROJECT_TYPES_REQUEST = 'PROJECT_TYPES_REQUEST';
export const PROJECT_TYPES_REQUEST_SUCCESS = 'PROJECT_TYPES_REQUEST_SUCCESS';
export const PROJECT_TYPES_REQUEST_FAILURE = 'PROJECT_TYPES_REQUEST_FAILURE';
export const STATUSES_REQUEST = 'STATUSES_REQUEST';
export const STATUSES_REQUEST_SUCCESS = 'STATUSES_REQUEST_SUCCESS';
export const STATUSES_REQUEST_FAILURE = 'STATUSES_REQUEST_FAILURE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_ACTIVE_STATUS = 'SET_ACTIVE_STATUS';
export const SET_SHOW_DETAILS = 'SET_SHOW_DETAILS';
export const SET_PROCESSING_PAYMENT = 'SET_PROCESSING_PAYMENT';
export const SET_PAYMENT_SUCCESS = 'SET_PAYMENT_SUCCESS';
export const CLEAR_SINGLE_PROJECT = 'CLEAR_SINGLE_PROJECT';
export const SET_VISIBLE_LAYERS = 'SET_VISIBLE_LAYERS';
export const SET_SHOW_COUNTRIES = 'SET_SHOW_COUNTRIES';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_REQUEST_END = 'AUTH_REQUEST_END';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT';
export const ON_IMAGERY_LOAD = 'ON_IMAGERY_LOAD';
export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const ON_DATA_SOURCE_DISPLAY_READY = 'ON_DATA_SOURCE_DISPLAY_READY';
