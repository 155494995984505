import { PROJECT_TYPES_REQUEST_SUCCESS } from '../actions/actions';

const initialState = [];

export function projectTypes(state = initialState, action) {
    switch(action.type) {
        case PROJECT_TYPES_REQUEST_SUCCESS: {
            const { projectTypes } = action;
            return {
                ...state,
                projectTypes
            };
        }
        default: {
            return state;
        }
    }
}
