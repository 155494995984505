import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './css/Popup.css';
import PopupHeader from './PopupHeader';
import AppLoader from './AppLoader';

import { api } from '../api';
import { gaEvent, gaEventTiming, trackingVars } from '../utils';
import './css/Popup.css';
import PopupHeaderVideo from './PopupHeaderVideo';

const TAG = 'ProjectPopup';

export default function ProjectPopup({ project, onHide }) {
    const { t } = useTranslation();
    let image = require(`../assets/images/countries/a_default_earth.jpg`);

    if ( project ) {
	gaEvent('user','projectpopup',trackingVars.projectName);
	gaEventTiming('user','projectpopup',trackingVars.projectName);
	trackingVars.projectName = project.name;
    }

    const projectType = project.type.toLowerCase();
    let header = null;
    if (!project.coverMedia) {
        header = <PopupHeader key={ project.id } onHide={ onHide } image={ image } badgeText={ project.type } className={ projectType } />
    } else if (project.coverMedia.vimeoId) {
        header = <PopupHeaderVideo key={ project.id } onHide={ onHide } vimeoId={ project.coverMedia.vimeoId } badgeText={ project.type } className={ projectType } />;
    } else if (project.coverMedia.path) {
        image = `${ api.defaults.publicURL }/${ project.coverMedia.path }`;
        header = <PopupHeader key={ project.id } onHide={ onHide } image={ image } badgeText={ project.type } className={ projectType } />
    }

    return (
        <>
          { header }
          <div className="popup-content">
            <h4>{ project.name }</h4>
            <p>{ project.description }</p>
            <Link to={ `/projects/${ project.id }/details` }>
                <Button
                    className="popup-button"
                    onClick={ onHide }
                    variant="primary"
                >
                    {t('projectPopup.button')}
                </Button>
            </Link>
          </div>
        </>
    );
}
