import {
    SET_VISIBLE_LAYERS,
    SET_SHOW_COUNTRIES,
    ON_IMAGERY_LOAD,
    SET_SELECTED_ENTITY,
    SET_SELECTED_LOCATION,
    ON_DATA_SOURCE_DISPLAY_READY
} from "./actions";

import ReactGA from 'react-ga';
import { gaEvent, gaEventTiming, gaSessionTiming } from '../utils';

export function setVisibleLayer(update) {

    if (update) {
	gaEvent('user','select layer',update.visibleLayer);
	gaEventTiming('user','select layer',update.visibleLayer);
    }

    return {
        type: SET_VISIBLE_LAYERS,
        update
    };
}

export function setShowCountries(showCountries) {
    return {
        type: SET_SHOW_COUNTRIES,
        showCountries
    };
}

export function onImageryLoad() {
    return {
        type: ON_IMAGERY_LOAD,
    };
}

export function onDataSourceDisplayReady() {
    return {
        type: ON_DATA_SOURCE_DISPLAY_READY,
    };
}

export function setSelectedEntity(selectedEntity) {
    return {
        type: SET_SELECTED_ENTITY,
        selectedEntity
    };
}

export function setSelectedLocation(selectedLocation) {
    return {
        type: SET_SELECTED_LOCATION,
        selectedLocation
    };
}
