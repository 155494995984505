import {
  COMPANIES_REQUEST,
  COMPANIES_REQUEST_SUCCESS,
  COMPANIES_REQUEST_FAILURE
} from './actions';
import { api } from '../api';

export function fetchCompanies() {
    return async (dispatch) => {
        const url = 'companies';
        dispatch(onFetchCompanies());
        try {
            const response = await api.get(url);
            const { companies } = response.data;
            dispatch(onFetchCompaniesSuccess(companies));
        } catch(error) {
            dispatch(onFetchCompaniesFailure(error));
        }
    };
}

function onFetchCompanies() {
    console.log('[onFetchCompanies]');
    return {
        type: COMPANIES_REQUEST
    };
}

function onFetchCompaniesSuccess(companies) {
    console.log('[onFetchCompaniesSuccess]', companies);
    return {
        type: COMPANIES_REQUEST_SUCCESS,
        companies
    };
}

function onFetchCompaniesFailure(error) {
    console.log('[onFetchCompaniesFailure]', error);
    return {
        type: COMPANIES_REQUEST_FAILURE,
        error
    };
}
