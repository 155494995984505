import {
    UPDATE_FILTERS,
    RESET_FILTERS
} from './actions';

export const updateFilters = (update) => {
    return {
        type: UPDATE_FILTERS,
        update
    };
}

export const resetFilters = () => {
    return {
        type: RESET_FILTERS
    };
}
