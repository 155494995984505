import {
    DONATION_OPTIONS_REQUEST,
    DONATION_OPTIONS_REQUEST_SUCCESS,
    DONATION_OPTIONS_REQUEST_FAILURE
} from './actions';
import { api } from '../api';

// Get all donation options
export function fetchDonationOptions() {
    return async dispatch => {
        const url = 'donation-options';
        dispatch(onFetchDonationOptions());
        try {
            const response = await api.get(url);
            const { donationOptions } = response.data;
            dispatch(onFetchDonationOptionsSuccess(donationOptions));
        } catch(error) {
            dispatch(onFetchDonationOptionsFailure(error));
        }
    };
}

function onFetchDonationOptions() {
    console.log('[onFetchDonationOptions]');
    return {
        type: DONATION_OPTIONS_REQUEST
    };
}

function onFetchDonationOptionsSuccess(donationOptions) {
    console.log('[onFetchDonationOptionsSuccess]', donationOptions);
    return {
        type: DONATION_OPTIONS_REQUEST_SUCCESS,
        donationOptions
    };
}

function onFetchDonationOptionsFailure(error) {
    console.log('[onFetchDonationOptionsFailure]', error);
    return {
        type: DONATION_OPTIONS_REQUEST_FAILURE,
        error
    };
}
