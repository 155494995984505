import React from 'react';
import { useTranslation } from 'react-i18next';

export default function About() {
  const { t } = useTranslation();

  return (
    <div className="about-content">
      <h2 className="mb-3">{t('about.title')}</h2>
      <p>{ t('about.startup') }</p>
      <p>{ t('about.charity') }</p>
      <p>{ t('about.prototype') }</p>
      <p>{ t('about.insights') }</p>
      <p>{ t('about.feedback') }</p>
      <p>{ t('about.faq') }</p>
      <p>{ t('about.thankYou') }</p>
      <p>{ t('about.regards') }</p>
      <p>{ t('about.ocwTeam') }</p>
    </div>
  );
}
