import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Ion } from 'cesium';

import App from './App';
import './i18n';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { fetchCompanies } from './actions/companies';
import { fetchDonationOptions } from './actions/donationOptions';
import { fetchProjects } from './actions/projects';
import { fetchProjectTypes } from './actions/projectTypes';
import { fetchStatuses } from './actions/statuses';
import { getMe } from './actions/auth';
import { CesiumIonAccessToken } from './enums';

// Event.composedPath polyfill
(function(e, d, w) {
  if(!e.composedPath) {
    e.composedPath = function() {
      if (this.path) {
        return this.path;
      }
    var target = this.target;

    this.path = [];
    while (target.parentNode !== null) {
      this.path.push(target);
      target = target.parentNode;
    }
    this.path.push(d, w);
    return this.path;
    }
  }
})(Event.prototype, document, window);

ReactGA.initialize('UA-178650821-1', {
    debug: false,
    gaOptions: {
	siteSpeedSampleRate: 100
    }
});
ReactGA.pageview(window.location.pathname + window.location.search);
if (window.performance) performance.mark('gatimer');

// is the event prevented or what?
window.addEventListener("hashchange", function(event) { ReactGA.modalview(event.newUrl); console.log('hashchanged ' + event.newUrl); }, false);

Ion.defaultAccessToken = CesiumIonAccessToken;

const positionOptions = {
  enableHighAccuracy: true,
  maximumAge: 0
};

store.dispatch(fetchCompanies());
store.dispatch(fetchDonationOptions());
store.dispatch(fetchProjects('PUBLISHED'));
store.dispatch(fetchProjectTypes());
store.dispatch(fetchStatuses());
store.dispatch(getMe());

function Root() {
  return (
    <Provider store={ store }>
      <App />
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));
