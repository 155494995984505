import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { setPaymentSuccess, setProcessingPayment } from '../actions/ui';
import api from '../stripeApi';
import { preventDefault } from '../utils';

import './css/CheckoutForm.css';

export default function CheckoutForm({ amount, projectDonationOptionId, onPaymentSuccess }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const paymentSuccess = useSelector(state => state.ui.paymentSuccess);
  const processingPayment = useSelector(state => state.ui.processingPayment);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [saveCard, setSaveCard] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  async function handleGetPaymentMethods() {
    if (user) {
      try {
        const methods = await api.getPaymentMethods({ userId: user.id });
        setPaymentMethods(methods);
      } catch (error) {
        console.error('[CheckoutForm] error:', error);
      }
    }
  }

  async function handleCreatePaymentIntent(metadata) {
    const options = paymentMethods.length > 0 ? {
      customer: paymentMethods[0].customer,
      payment_method: paymentMethods[0].id,
      off_session: true,
      confirm: true
    } : {};

    try {
      const clientSecret = await api.createPaymentIntent({ ...options, amount, metadata })
      setClientSecret(clientSecret);
    } catch (error) {
      console.error('[CheckoutForm] error:', error);
    }
  }

  useEffect(() => {
    const userId = user ? user.id : null;

    // Step 1: Create PaymentIntent over Stripe API
    if (amount > 0 && projectDonationOptionId > 0 && userId > 0) {
      const metadata = { projectDonationOptionId, userId };
      handleGetPaymentMethods();
      handleCreatePaymentIntent(metadata);
    }
  }, [amount, projectDonationOptionId, user]);

  useEffect(() => {
    setProcessing(false);
  }, [error]);

  const handleSubmit = async (e) => {
    preventDefault(e);
    dispatch(setProcessingPayment({ processingPayment: true }));
    setProcessing(true);

    // Step 2: Use clientSecret from PaymentIntent and the CardElement to confirm payment with stripe.confirmCardPayment()
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: e.target.name.value
        }
      },
      ...(saveCard && { setup_future_usage: 'off_session' })
    });

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      dispatch(setProcessingPayment({ processingPayment: false }));
      setProcessing(false);
      console.log('[error]', payload.error);
    } else {
      onPaymentSuccess();

      setError(null);
      dispatch(setPaymentSuccess({ paymentSuccess: true }));
      setSuccess(true);
      dispatch(setProcessingPayment({ processingPayment: false }));
      setProcessing(false);
      setMetadata(payload.paymentIntent);
      // console.log('[PaymentIntent]', payload.paymentIntent);
    }
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: '#4d4b29',
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#4d4b29'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      },
    };

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Control
          type="text"
          id="name"
          name="name"
          placeholder={ t('checkoutForm.name') }
          autoComplete="cardholder"
          required />
        <CardElement
          className="sr-input sr-card-element"
          options={options} />
        <Form.Check
          type="checkbox"
          className="save-card"
          id="save-card"
          name="save-card"
          checked={ saveCard }
          onChange={ () => setSaveCard(!saveCard) }
          label={ t('checkoutForm.saveCard') } />
        { error &&
          <Alert
            className="mb-2 mt-2"
            variant="danger"
            dismissible
            onClose={() => setError(null)}>
            {error}
          </Alert>
        }

        <Button
          variant="primary"
          block
          disabled={processing || !clientSecret || !stripe}
          type="submit">
          { processing ? t('checkoutForm.processing') : t('checkoutForm.pay') }
        </Button>
      </Form>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {!success && renderForm()}
      </div>
    </div>
  );
}
