import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './css/Popup.css';
import PopupHeader from './PopupHeader';

import { api } from '../api';
import { gaEvent, gaEventTiming } from '../utils';
import './css/Popup.css';


export default function VideoPopup({ video, onHide }) {
    if ( video ) {
      gaEvent('user','videopopup',video.location);
      gaEventTiming('user','videopopup',video.location);
    }

    return (
        <>
          <PopupHeader onHide={ onHide } className="video" />

          <div key={ video.url } className="popup-content video">
            <div className="popup-content-video-wrapper">
              <embed src={`${video.url}?controls=0`}></embed>
            </div>
            <div className="video-info">
              <h4>{ video.location }</h4>
              <small>{ video.date }</small>
            </div>
          </div>
        </>
    );
}
