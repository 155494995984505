let errorCounter = 1;

export const error = (
    state = { errors: [] },
    action
) => {
    const { error } = action;
    if (error) {
        console.error(error);
        // const { message } = error;
        // const id = errorCounter++;
        // const newError = {
        //     id,
        //     message
        // };
        // const errors = [
        //     ...state.errors,
        //     newError
        // ];
        // return {
        //     ...state,
        //     errors
        // };
    }
    return state;
    // switch(action.type) {
    //     case DISMISS_ERROR: {
    //         const { id } = action;
    //         const errors = state.errors.filter(error => error.id !== id);
    //         return {
    //             ...state,
    //             errors
    //         };
    //     }
    //     default: {
    //         return state;
    //     }
    // }
}