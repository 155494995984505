import {
    SET_ACTIVE_STATUS,
    SET_SHOW_DETAILS,
    SET_PROCESSING_PAYMENT,
    SET_PAYMENT_SUCCESS
} from './actions';

export const setActiveStatus = (activeStatus) => {
    return {
        type: SET_ACTIVE_STATUS,
        activeStatus
    };
}

export const setShowDetails = (showDetails) => {
    return {
        type: SET_SHOW_DETAILS,
        showDetails
    };
}

export const setProcessingPayment = (processingPayment) => {
    return {
        type: SET_PROCESSING_PAYMENT,
        processingPayment
    };
}

export const setPaymentSuccess = (paymentSuccess) => {
    return {
        type: SET_PAYMENT_SUCCESS,
        paymentSuccess
    };
}
