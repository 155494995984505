import React from 'react';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faLock, faPen } from '@fortawesome/free-solid-svg-icons';

import { api } from '../api';
import ManagePaymentMethods from './ManagePaymentMethods';
import ChangePasswordForm from './ChangePasswordForm';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import ProfileForm from './ProfileForm';
import {
  useHistory,
  useParams,
  useRouteMatch,
  Link
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../actions/auth';
import AppLoader from './AppLoader';

import './css/SidebarMenu.css';
import About from './About';
import FAQ from './FAQ';

export default function SidebarMenu({ show, onHide }) {
  const { page } = useParams();
  const match = useRouteMatch();
  const isFetching = useSelector(state => state.auth.isFetching);

  let sidebarContent = null;
  if (match) {
    switch(page) {
      case 'login': {
        sidebarContent = <LoginForm />;
        break;
      }
      case 'signup': {
        sidebarContent = <SignUpForm />;
        break;
      }
      case 'edit-profile': {
        sidebarContent = <ProfileForm />;
        break;
      }
      case 'change-password': {
        sidebarContent = <ChangePasswordForm />;
        break;
      }
      case 'manage-payment-methods': {
        sidebarContent = <ManagePaymentMethods />;
        break;
      }
      case 'about-us': {
        sidebarContent = <About />;
        break;
      }
      case 'faq': {
        sidebarContent = <FAQ />;
        break;
      }
      default: {
        sidebarContent = <MenuNav />;
      }
    }
  }

  return (
    <Sidebar
      headerOptions={{
        backRoute: page ? '/menu' : ''
      }}
      content={
        <>
          <AppLoader active={ isFetching } component={ true } />
          { sidebarContent }
        </>
      }
      open={show}
    />
  );
}

function MenuNav() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory()
  const user = useSelector(state => state.auth.user);
  const path = history.location.pathname;

  async function handleLogout() {
    await dispatch(logout());
    history.push('/');
  }

  return (
    <div className="sidebar-menu-nav">
      { user ?
        <>
          { user.profilePicture && <img className="profile-picture" alt="User's profile" src={`${ api.defaults.publicURL }/${ user.profilePicture }`} /> }
          <div className="d-flex align-self-start">
            <div className="flex-column text-right">
              <p>{t('sidebarMenu.forename')}</p>
              <p>{t('sidebarMenu.surname')}</p>
              <p>{t('sidebarMenu.tagline')}</p>
              <p>{t('sidebarMenu.email')}</p>
            </div>
            <div className="flex-column ml-5">
              <p>{user.forename ? user.forename : '-'}</p>
              <p>{user.surname ? user.surname : '-'}</p>
              <p>{user.tagline ? user.tagline : '-'}</p>
              <p>{user.email ? user.email : '-'}</p>
            </div>
          </div>
          <Button variant="secondary">
            <FontAwesomeIcon icon={faPen} /> <Link to={ `${ path }/edit-profile` }>
              {t('sidebarMenu.editProfile')}
            </Link>
          </Button>
          <Button variant="secondary">
            <FontAwesomeIcon icon={faLock} /> <Link to={ `${ path }/change-password` }>
              {t('sidebarMenu.changePassword')}
            </Link>
          </Button>
          <Button variant="secondary">
            <FontAwesomeIcon icon={faCreditCard} /> <Link to={ `${ path }/manage-payment-methods` }>
              {t('sidebarMenu.managePaymentMethods')}
            </Link>
          </Button>
        </> :
        <>
          <iframe
            className="tutorial-video"
            title="Our Common World tutorial video"
            src="https://player.vimeo.com/video/542547769?badge=0&amp;autopause=0&amp;autoplay=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="fullscreen;">
          </iframe>
          <div className="sidebar-menu-auth">
            <Link to={ `${ path }/login` }>
              <Button block variant="outline-info">
                {t('sidebarMenu.login')}
              </Button>
            </Link>
            <Link to={ `${ path }/signup` }>
              <Button block variant="primary">
                {t('sidebarMenu.signUp')}
              </Button>
            </Link>
          </div>
        </>
      }
      <a href="mailto:feedback@ourcommonworld.org?subject=Feedback from App">
        {t('sidebarMenu.feedback')}
      </a>
      <Link to={ `${ path }/about-us` }>
        {t('sidebarMenu.aboutUs')}
      </Link>
      <Link to={ `${ path }/faq` }>
        {t('sidebarMenu.faq')}
      </Link>
      { user &&
        <>
          <Link to={ '/create-project' }>
            {t('sidebarMenu.createProject')}
          </Link>
          <Link to={ '/my-projects' }>
            {t('sidebarMenu.myProjects')}
          </Link>
          <Link to={ '/pending-projects' }>
            {t('sidebarMenu.pendingProjects')}
          </Link>
          <Link onClick={ handleLogout }>
            {t('sidebarMenu.logout')}
          </Link>
        </>
      }
    </div>
  )
}
