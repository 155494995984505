import React from 'react';
import {
    Cartesian3,
    VerticalOrigin,
    HorizontalOrigin
} from 'cesium';
import {
    Entity
} from 'resium';
import {
    getBillboardEyeOffset,
    getBillboardVisibility
} from '../globeUtils';
import { Partner } from '../enums';


export function VideoEntity({
    camera,
    video
}) {
    const position = Cartesian3.fromDegrees(video.lng, video.lat);
    const billboard = {
    eyeOffset: camera ? getBillboardEyeOffset(camera) : new Cartesian3(0, 0, -5000000),
    show: camera ? getBillboardVisibility(position, camera) : true,
    image: Partner.Pin[video.partnerId],
    scale: 0.8,
    verticalOrigin: VerticalOrigin.BOTTOM,
    horizontalOrigin: HorizontalOrigin.CENTER
    };

    return (
    <Entity
        key={video.id}
        position={position}
        video={video}
        billboard={billboard}>
    </Entity>
    );
}