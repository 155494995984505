import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { Cartesian3 } from 'cesium';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGlobeAfrica, faHome, faMapMarkerAlt, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import MenuMobile from './MenuMobile';
import OurWorldListItem from './OurWorldListItem';
import ProjectsListItem from './ProjectsListItem';
import ShareButton from '../ShareButton';
import UserStatus from './UserStatus';
import { isTouchDevice } from '../../utils';

import './NavigationBar.css';

const TAG = '[NavigationBar]';

export default function NavigationBar({ viewerRef, initialLocation }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const partners = useSelector(state => state.companies.companies);
  const projectTypes = useSelector(state => state.projectTypes.projectTypes);
  const projectFilters = useSelector(state => state.projectFilters);
  const statuses = useSelector(state => state.statuses.statuses);
  const [activeMobileMenu, setActiveMobileMenu] = useState(null);
  const layers = [
    {
      name: 'childrenOutOfPrimarySchoolPercentage',
      label: t('dataLabels.childrenOutOfPrimarySchoolPercentage')
    },
    {
      name: 'undernourishmentPercentage',
      label: t('dataLabels.undernourishmentPercentage')
    },
    {
      name: 'adultLiteracy',
      label: t('dataLabels.adultLiteracy')
    },
    {
      name: 'temperatureRise',
      label: t('dataLabels.temperatureRise')
    },
    {
      name: 'forestArea',
      label: t('dataLabels.forestArea')
    }
  ];

  function handleHomeClick() {
    if (viewerRef.current) {
      const { camera } = viewerRef.current.cesiumElement;
      const { lat, lon, height } = initialLocation;

      camera.flyTo({
        destination: Cartesian3.fromDegrees(lon, lat, height),
        duration: 1.0
      });
    }
  }

  let navbarMobileContent = null;

  switch (activeMobileMenu) {
    case 'Our World':
      navbarMobileContent = <>
        <OurWorldListItem />
        { layers.map((layer) => <OurWorldListItem layer={ layer } />) }
      </>
      break;
    case 'Projects':
      navbarMobileContent = <>
        { user ?
          user.role === 'ADMIN' && statuses &&
            <ListGroup.Item key="statuses" disabled>
              <h6 className="mb-0">{ t('navbar.statuses') }</h6>
            </ListGroup.Item> :
          null }
        { user ?
            user.role === 'ADMIN' && statuses &&
            statuses.map((status) => <ProjectsListItem status={ status } />) :
          null }
        { projectTypes &&
          <ListGroup.Item key="project-types" disabled>
            <h6 className="mb-0">{ t('navbar.projectTypes') }</h6>
          </ListGroup.Item> }
        { projectTypes &&
          projectTypes.filter((type) => !type.companyId).map((projectType) => <ProjectsListItem projectType={ projectType } />) }
        { partners &&
          <ListGroup.Item key="partners" disabled>
          </ListGroup.Item> }
        { partners &&
          partners.map((partner) => <ProjectsListItem partner={ partner } />) }
      </>
      break;
    default:
      console.warn(`${TAG} unrecognized mobile menu`);
  }

  let navbarContent = isTouchDevice() ? (
    <>
      <Nav.Link
        className="navbar-desktop-item nav-link-left menu-trigger"
        as={ Link }
        to={ '/menu' }>
        <FontAwesomeIcon icon={ faBars } />
        <UserStatus user={ user } />
      </Nav.Link>
      <p className="nav-link-right-search-text">
        { projectFilters.string.length > 11 ? `${projectFilters.string.substring(0, 11)}…` : projectFilters.string }
      </p>
      <div className="nav-link-right-container">
        <Nav.Link
          className="nav-link-right"
          as={ Link }
          to={ '/search' }>
          <FontAwesomeIcon icon={ faSearch } />
        </Nav.Link>
        <Nav.Item className="nav-link-right">
          <ShareButton />
        </Nav.Item>
        <div className="nav-item-right-container">
          <Nav.Item
            className={ `nav-link-right ${activeMobileMenu === 'Our World' && ' active'}` }
            onClick={ () => setActiveMobileMenu('Our World') }>
            <FontAwesomeIcon icon={ faGlobeAfrica } />
          </Nav.Item>
          <Nav.Item
            className={ `nav-link-right ${activeMobileMenu === 'Projects' && ' active'}` }
            onClick={ () => setActiveMobileMenu('Projects') }>
            <FontAwesomeIcon icon={ faMapMarkerAlt } />
          </Nav.Item>
          <Nav.Item
            className={ 'nav-link-right' }
            onClick={ handleHomeClick }>
            <FontAwesomeIcon icon={ faHome } />
          </Nav.Item>
        </div>
      </div>
      <MenuMobile active={ activeMobileMenu !== null }>
        <Row className="justify-content-between" noGutters>
          <h2 className="align-self-center">{ activeMobileMenu }</h2>
          <Button
            className="align-self-center"
            onClick={ () => setActiveMobileMenu(null) }>
            <FontAwesomeIcon icon={ faTimes } />
          </Button>
        </Row>
        <ListGroup>
          { navbarMobileContent }
        </ListGroup>
      </MenuMobile>
    </>) : (
      <Navbar variant="dark" fixed="top" className="navbar-desktop">
        <Navbar.Collapse className="navbar-content-wrapper" id="responsive-navbar-nav">
          <Nav className="navbar-desktop-item menu-trigger">
            <Nav.Link
              as={ Link }
              to={ '/menu' }>
              <FontAwesomeIcon icon={ faBars } />
            </Nav.Link>
            <UserStatus user={ user } />
          </Nav>
          <Nav>
            <Nav.Link
              className="navbar-desktop-item rounded-0"
              onClick={ handleHomeClick }>
              <FontAwesomeIcon icon={ faHome } />
            </Nav.Link>
            <NavDropdown
              id="collasible-nav-world"
              className="navbar-desktop-item main-dropdown"
              title={
                <>
                  <div>
                    <FontAwesomeIcon className="mr-5" icon={ faGlobeAfrica } />
                  </div>
                  <span>
                    { t('navbar.world') }
                  </span>
                </>
              }>
              <OurWorldListItem />
              { layers.map((layer) => <OurWorldListItem layer={ layer } />) }
            </NavDropdown>
            <NavDropdown
              id="collasible-nav-projects"
              className="navbar-desktop-item main-dropdown"
              title={
                <>
                  <div>
                    <FontAwesomeIcon className="mr-5" icon={ faMapMarkerAlt } />
                  </div>
                  <span>
                    { t('navbar.projects') }
                  </span>
                </>
              }>
              { user ?
                user.role === 'ADMIN' && statuses &&
                  <ListGroup.Item key="statuses" disabled>
                    <h6 className="mb-0">{ t('navbar.statuses') }</h6>
                  </ListGroup.Item> :
                null }
              { user ?
                user.role === 'ADMIN' && statuses &&
                  statuses.map((status) => <ProjectsListItem status={ status } />) :
                null }
              { projectTypes &&
                <ListGroup.Item key="project-types" disabled>
                  <h6 className="mb-0">{ t('navbar.projectTypes') }</h6>
                </ListGroup.Item> }
              { projectTypes &&
                projectTypes.filter((type) => !type.companyId).map((projectType) => <ProjectsListItem projectType={ projectType } />) }
              { partners &&
                <ListGroup.Item key="partners" disabled>
                </ListGroup.Item> }
              { partners &&
                partners.map((partner) => <ProjectsListItem partner={ partner } />) }
            </NavDropdown>
            <Nav.Link
              className={ `navbar-desktop-item search-toggle ${ projectFilters.string.length ? 'search-active' : '' }` }
              as={ Link }
              to={ '/search' }>
              <FontAwesomeIcon icon={ faSearch } />
              {/* { projectFilters.string.length > 8 ? `${projectFilters.string.substring(0, 23)}…` : projectFilters.string } */}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>);

  return (navbarContent);
}

// function AnimatableDropdown({
//   children,
//   id = '',
//   className= '',
//   title
// }) {
//   const [open, setOpen] = useState(false);
//   const dropdownRef = useRef();
//   const wrapperRef = useRef();

//   useEffect(() => {
//     function handleTransitionEnd(event) {
//       if (
//         !event.target.className.includes('open')
//         || !(dropdownRef.current && dropdownRef.current.firstChild)
//       ) {
//         return;
//       }
//       dropdownRef.current.firstChild.click();
//     }
//     if (wrapperRef.current) {
//       wrapperRef.current.addEventListener('transitionend', handleTransitionEnd);
//     }
//     return function cleanup() {
//       if (wrapperRef.current) {
//         wrapperRef.current.removeEventListener('transitionend', handleTransitionEnd);
//       }
//     }
//   }, wrapperRef.current);

//   function handleToggleDropdown() {
//     setOpen(!open);
//   }

//   return (
//     <div
//       ref={ wrapperRef }
//       className={ `animatable-dropdown-wrapper ${ open ? 'open' : ''}` }
//     >
//       <div
//         onClick={ handleToggleDropdown }
//         className={ `animatable-dropdown-overlay` }
//         ></div>

//       <NavDropdown
//         ref={ dropdownRef }
//         id={ id }
//         className={ `navbar-desktop-item main-dropdown ${ className }` }
//         title={ title }
//         style={{
//           width: '100%'
//         }}
//       >
//         { children }
//       </NavDropdown>
//     </div>
//   )
// }
