import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router } from "react-router-dom";

import Globe from './components/Globe';
import NavigationBar from './components/NavigationBar';
import ShareButton from './components/ShareButton';
import Sidebars from './components/Sidebars';
import SplashScreen from './components/SplashScreen';
import { isTouchDevice } from './utils';
import Legend from './components/data-layers/Legend';
import { colors } from './components/data-layers/colors';
import { useTranslation } from 'react-i18next';
import { LocationPicker } from './components/LocationPicker';

// This is needed in Globe and NavigationBar.
// It was chosen to be passed as a prop due to its static nature.
const initialLocation = {
  lat: 20,
  lon: 0,
  height: 2e7
};

export default function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appState = useSelector(state => state.app.appState);
  const visibleLayer = useSelector(state => state.globe.visibleLayer);
  const viewerRef = useRef();
  const colorSet = colors[visibleLayer];

  useEffect(() => {
    ReactGA.event({
      category: 'App',
      action: 'App loaded'
    });
  }, [dispatch]);

  console.count('[App]');

  return (
    <Container
      fluid
      style={{ padding: 0, height: '100%' }}
    >
      { !['READY', 'PICK_LOCATION'].includes(appState) &&
        <SplashScreen
          active={ ['LOADING', 'LOADED'].includes(appState) }
          ready={ appState === 'LOADED' }
        />
      }

      { colorSet &&
        <Legend
          items={ colorSet }
          title={ t(`dataLabels.${ visibleLayer }`)}
        />
      }

      <Router>
        { appState !== 'PICK_LOCATION' &&
          <NavigationBar viewerRef={ viewerRef } initialLocation={ initialLocation } />
        }
        <div className={ `main-content` }>
            <Sidebars />
          { !isTouchDevice() && appState !== 'PICK_LOCATION' && <ShareButton className="btn-share-main" disabled={ appState === 'PICK_LOCATION' } /> }
          { viewerRef.current && <LocationPicker viewerRef={ viewerRef } /> }
          <div className="globe-container">
            <Globe viewerRef={ viewerRef } initialLocation={ initialLocation } />
          </div>
          <p className="copyright">&copy; all rights reserved by U2 Online Oy 2020.</p>
        </div>
      </Router>
    </Container>
  );
}

// This is if you need to adjust main content width when opening sidebars
// function Content() {
//   const location = useLocation();
//   const base = location.pathname.split('/')[1];

//   let marginClassName;
//   switch(base) {
//     case 'menu': {
//       marginClassName = 'expand-left';
//       break;
//     }
//     case 'search':
//     case 'projects': {
//       marginClassName = 'expand-right';
//       break;
//     }
//     default: {
//       marginClassName = '';
//     }
//   }

//   return (

//   );
// }
