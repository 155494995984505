import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import { fetchProjects } from '../../../actions/projects';
import { updateFilters } from '../../../actions/projectFilters';
import { Partner, ProjectType } from '../../../enums';

export default function ProjectsListItem({ partner, projectType, status }) {
  const dispatch = useDispatch();
  const projectFilters = useSelector(state => state.projectFilters);
  const { projectTypeIds, projectTypePartnerIds } = projectFilters;
  const projectTypes = useSelector(state => state.projectTypes.projectTypes);
  const activeStatus = useSelector(state => state.ui.activeStatus);

  function handleTogglePartner(partnerId) {
    if (projectTypePartnerIds.includes(partnerId)) {
      dispatch(updateFilters({
        projectTypePartnerIds: projectTypePartnerIds.filter((id) => id !== partnerId)
      }));
    } else {
      dispatch(updateFilters({
        projectTypePartnerIds: [...projectTypePartnerIds, partnerId]
      }));
    }
  }

  function handleToggleProjectType(projectTypeId) {
    if (projectTypeIds.includes(projectTypeId)) {
      dispatch(updateFilters({
        projectTypeIds: projectTypeIds.filter((id) => id !== projectTypeId)
      }));
    } else {
      dispatch(updateFilters({
        projectTypeIds: [...projectTypeIds, projectTypeId]
      }));
    }
  }

  // A project type can belong to a partner and still be under Partners heading.
  // Thus all this mangling.
  function handleToggleProjectTypeOfPartner() {
    const findProjectType = type => type.companyId === partner.id;
    const partnerId = projectTypes.find(findProjectType) ? projectTypes.find(findProjectType).id : null;

    if (partnerId) {
      handleToggleProjectType(partnerId);
    }
    handleTogglePartner(partner.id);
  }

  function handleStatusClick(status) {
    dispatch(fetchProjects(status));
  }

  if (status) {
    return (
      <ListGroup.Item
        key={ status.id }
        onClick={ () => handleStatusClick(status.status) }>
        <Row className="justify-content-between" noGutters>
          <div>{ status.status }</div>
          <div className={`list-group-item-checkbox ${status.status === activeStatus && 'active'}`}>
            {
              status.status === activeStatus ?
                <FontAwesomeIcon icon={faCheckCircle} /> :
                <FontAwesomeIcon icon={faCircle} />
            }
          </div>
        </Row>
      </ListGroup.Item>
    );
  } else if (projectType) {
    return (
      <ListGroup.Item
        key={ projectType.id }
        onClick={ () => handleToggleProjectType(projectType.id) }>
        <Row className="justify-content-between" noGutters>
          <div>
            <img
              className="list-group-item-icon"
              alt={ projectType.type }
              src={ ProjectType.Icon[projectType.id] } /> { projectType.type }
          </div>
          <div className={`list-group-item-checkbox ${projectTypeIds.includes(projectType.id) && 'active'}`}>
            {
              projectTypeIds.includes(projectType.id) ?
                <FontAwesomeIcon icon={faCheckSquare} /> :
                <FontAwesomeIcon icon={faSquare} />
            }
          </div>
        </Row>
      </ListGroup.Item>
    );
  } else if (partner) {
    return (
      <ListGroup.Item
        key={ `partner-${partner.id}` }
        onClick={ () => handleToggleProjectTypeOfPartner(partner.id) }>
        <Row className="justify-content-between" noGutters>
          <div>
            <img
              className="list-group-item-logo"
              alt={ partner.name }
              src={ Partner.Icon[partner.id] } />
          </div>
          <div className={`list-group-item-checkbox ${projectTypePartnerIds.includes(partner.id) && 'active'}`}>
            {
              projectTypePartnerIds.includes(partner.id) ?
                <FontAwesomeIcon icon={faCheckSquare} /> :
                <FontAwesomeIcon icon={faSquare} />
            }
          </div>
        </Row>
      </ListGroup.Item>
    );
  } else {
    return null;
  }
}
