import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function PopupHeaderVideo({ vimeoId, onHide, className, badgeText }) {
    return (
      <>
        <div className={ `popup-header video ${ className ? className : '' } ${ !badgeText ? 'no-badge' : ''}` }>
            { badgeText &&
              <Badge
                pill
                variant="light"
              >
                { badgeText }
              </Badge>
            }
            <FontAwesomeIcon className="popup-close" onClick={ onHide } icon={ faTimes } />
        </div>
        <div className="popup-content-video-wrapper">
          <embed src={ 'https://player.vimeo.com/video/' + vimeoId + '?autoplay=1&muted=1&controls=0&loop=1'} frameborder="0"></embed>
        </div>
      </>
    )
}