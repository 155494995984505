import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import './Legend.css';

export default function Legend({
    items,
    title
}) {
    if (!items || !items.colors) {
        return null;
    }
    const colorItems = items.colors.slice(0);
    colorItems.reverse();
    const unitPosition = [36].includes(items.unit) ? 'before' : 'after';
    const unitString = String.fromCharCode(items.unit);
    return (
        <div className="data-legend-accordion">
            <Accordion defaultActiveKey="0">
                <CustomToggle
                    eventKey="0"
                    title={ title }
                />
                <Accordion.Collapse eventKey="0">
                    <div>
                        { colorItems.map((item, index, array) => {
                            let value = `${ item.limit }`;
                            if (item.label) {
                                value = item.label;
                            } else {
                                const nextItem = array[index + 1];
                                if (nextItem) {
                                    value = `${value} - ${ nextItem.limit }`;
                                } else {
                                    value = `> ${ value }`;
                                }
                            }

                            return (
                                <div className="data-legend-item">
                                    <div
                                        className="data-legend-color"
                                        style={{
                                            backgroundColor: `#${ item.color }`
                                        }}
                                    ></div>
                                    <div className="data-legend-value">
                                        { unitPosition === 'before' && unitString } { value } { unitPosition === 'after' && unitString }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}

function CustomToggle({ className, title, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div className={ `data-legend-toggle ${ className ? className : '' }` } onClick={ decoratedOnClick }>
            <span>{ title }</span>
            <div className="data-legend-caret-wrapper">
                <FontAwesomeIcon className={ `data-legend-caret ${ isCurrentEventKey ? 'active' : '' }` } icon={ faAngleRight } />
            </div>
        </div>
    );
}