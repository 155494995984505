import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../actions/auth';
import AppLoader from './AppLoader';

export default function LoginForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isFetching = useSelector(state => state.auth.isFetching);
  const [loginError, setLoginError] = useState();

  const formik = useFormik({
    initialValues: {
        email: '',
        password: ''
    },
    validationSchema: Yup.object({
        email: Yup.string().email(t('errors.invalidEmail'))
            .required(t('errors.required')),
        password: Yup.string()
            .required(t('errors.required')),
    }),
    onSubmit: async function handleSubmit(values, { resetForm }) {
        try {
          await dispatch(login(values));
          history.push('/menu');
        } catch(error) {
          if (error.response.status === 401) {
            setLoginError(t('errors.invalidCredentials'));
          } else {
            setLoginError('errors.generalError');
          }
        }
    }
  });

  return (
    <>
      <Form
        className="login-form"
        noValidate
        onSubmit={ formik.handleSubmit }
      >
        <Form.Group>
          <Form.Control
              id="email"
              name="email"
              type="email"
              placeholder={t('loginForm.email') }
              value={ formik.values.email }
              onChange={ formik.handleChange }
              onBlur={formik.handleBlur}
              isInvalid={ formik.touched.email && formik.errors.email }
          />
          {formik.touched.email && formik.errors.email ? (
              <Form.Control.Feedback type="invalid">
                  { formik.errors.email }
              </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Control
              id="password"
              name="password"
              type="password"
              placeholder={t('loginForm.password') }
              value={ formik.values.password }
              onChange={ formik.handleChange }
              onBlur={formik.handleBlur}
              isInvalid={ formik.touched.password && formik.errors.password }
          />
          {formik.touched.password && formik.errors.password ? (
              <Form.Control.Feedback type="invalid">
                  { formik.errors.password }
              </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Button
          variant="primary"
          block
          type="submit"
        >
          {t('loginForm.login')}
        </Button>
        { loginError &&
          <Alert className="mt-3" variant="danger">{ loginError }</Alert>
        }
      </Form>
    </>
    );
}
