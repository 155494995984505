import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import { globe } from './reducers/globe';
import { companies } from './reducers/companies';
import { donationOptions } from './reducers/donationOptions';
import { projectFilters } from './reducers/projectFilters';
import { projects } from './reducers/projects';
import { projectTypes } from './reducers/projectTypes';
import { statuses } from './reducers/statuses';
import { ui } from './reducers/ui';
import { error } from './reducers/error';
import { auth } from './reducers/auth';
import { app } from './reducers/app';

const appReducer = combineReducers({
    app,
    auth,
    error,
    globe,
    companies,
    donationOptions,
    projectFilters,
    projects,
    projectTypes,
    statuses,
    ui
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    appReducer,
    {},
    composeEnhancers(
        applyMiddleware(
        ReduxThunk
    ))
);

export default store;
