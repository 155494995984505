import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Cartesian3,
  ScreenSpaceEventType,
  VerticalOrigin,
  HorizontalOrigin,
  IonImageryProvider
} from 'cesium';
import {
  Viewer,
  ScreenSpaceCameraController,
  CustomDataSource,
  Entity
} from 'resium';
import { useHistory } from 'react-router-dom';

import { getVisiblePartners, getVisibleProjects } from '../reducers/projects';
import InfoBox from './InfoBox';
import ProjectPopup from './ProjectPopup';
import VideoPopup from './VideoPopup';
import CountryPopup from './CountryPopup';

import GlobeLayers from './GlobeLayers';
import { onImageryLoad, onDataSourceDisplayReady } from '../actions/globe';
import { setAppState } from '../actions/app';

import { CesiumIonAccessToken } from '../enums';
import pin from '../assets/pin/geo.svg';
import cameraFlightPath from '../data/camera-flight.json';
import {
  cameraFlyPath,
  getSelectedEntityDestination,
  updateProjectMarkerVisibility,
  updateVideoMarkerVisibility,
  updateCountryLabelVisibility,
  setupCountryEntities,
  setupActiveEntities,
  updateActiveLayer
} from '../globeUtils';
import { ProjectEntity } from './ProjectEntity';
import { VideoEntity } from './VideoEntity';

const TAG = '[Globe]';

const imageryProvider = new IonImageryProvider({
  accessToken: CesiumIonAccessToken,
  assetId: 3954
});

const billboard = {
  image: pin,
  scale: 0.8,
  eyeOffset: new Cartesian3(0, 0, 100),
  verticalOrigin: VerticalOrigin.BOTTOM,
  horizontalOrigin: HorizontalOrigin.CENTER
};

export default function Globe({ viewerRef, initialLocation }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const partnerVideos = useSelector(state => state.projects.partnerVideos);
  const projects = useSelector(state => state.projects.projects);
  const appState = useSelector(state => state.app.appState);
  const projectFilters = useSelector(state => state.projectFilters);
  const selectedLocation = useSelector(state => state.globe.selectedLocation);
  const showProjects = useSelector(state => state.globe.showProjects);
  const visibleLayer = useSelector(state => state.globe.visibleLayer);

  const [cameraStartLocation, setCameraStartLocation] = useState();
  const [selectedEntity, setSelectedEntity] = useState(null);

  const projectMarkers = useRef(null);
  const videoMarkers = useRef(null);
  const countriesLayer = useRef(null);
  const activeLayer = useRef(null);
  const locationMarker = useRef(null);

  useEffect(() => {
    switch(appState) {
      case 'INTRO': {
        if (window.localStorage.getItem('hasSeenIntro') !== 'true') {
          const { camera } = viewerRef.current.cesiumElement;

          cameraFlyPath({
            camera,
            path: cameraFlightPath.path,
            cb: () => dispatch(setAppState('READY')),
            onSelectEntity: (entity) => setSelectedEntity(entity),
            countriesLayer,
            projectMarkers
          });

          window.localStorage.setItem('hasSeenIntro', true);
        } else {
          dispatch(setAppState('READY'));
        }
        break;
      }
      case 'READY': {
        const { clock, camera } = viewerRef.current.cesiumElement;
        if (projectMarkers.current) {
          updateProjectMarkerVisibility.apply({
            camera,
            clock,
            projectMarkers
          });
        }
        if (videoMarkers.current) {
          updateVideoMarkerVisibility.apply({
            camera,
            clock,
            videoMarkers
          });
        }
        if (countriesLayer.current) {
          updateCountryLabelVisibility.apply({
            camera,
            clock,
            countriesLayer
          });
        }
        break;
      }
      default:
        if (appState !== 'PICK_LOCATION') {
          console.warn(`${TAG} unrecognized app state`);
        }
    }
  }, [appState, dispatch, viewerRef]);

  useEffect(() => {
    const { dataSourceDisplay, cesiumWidget, scene, dataSources, clock, camera } = viewerRef.current.cesiumElement;

    if (!cameraStartLocation && cameraFlightPath.start) {
      let { lat, lon, height } = cameraFlightPath.start;
      if (window.localStorage.getItem('hasSeenIntro') === 'true') {
        lat = initialLocation.lat;
        lon = initialLocation.lon;
        height = initialLocation.height;
      }
      camera.flyTo({
        destination: Cartesian3.fromDegrees(lon, lat, height),
        duration: 0
      });
      setCameraStartLocation(true);
    }
    camera.percentageChanged = 0.1;

    // Poll the datasource display for handling loading state
    const dataSourceDisplayInterval = setInterval(() => {
      const { ready } = dataSourceDisplay;
      if (ready) {
        dispatch(onDataSourceDisplayReady());
        clearInterval(dataSourceDisplayInterval);
      }
    }, 500)

    scene.globe.tileLoadProgressEvent.addEventListener(function loadProgressListener(queueLength) {
      if (queueLength === 0 && appState === 'LOADING') {
        dispatch(onImageryLoad());
      }
    });

    viewerRef.current.cesiumElement.camera.changed.addEventListener(updateProjectMarkerVisibility, {
      projectMarkers,
      clock,
      camera
    });

    viewerRef.current.cesiumElement.camera.changed.addEventListener(updateProjectMarkerVisibility, {
      projectMarkers: locationMarker,
      clock,
      camera
    });

    viewerRef.current.cesiumElement.camera.changed.addEventListener(updateVideoMarkerVisibility, {
      videoMarkers,
      clock,
      camera
    });

    viewerRef.current.cesiumElement.camera.changed.addEventListener(updateVideoMarkerVisibility, {
      videoMarkers: locationMarker,
      clock,
      camera
    });

    viewerRef.current.cesiumElement.camera.changed.addEventListener(updateCountryLabelVisibility, {
      countriesLayer,
      clock,
      camera
    });

    dataSources.dataSourceAdded.addEventListener((event, dataSource) => {
      if (dataSource.name === 'projectMarkers') {
        projectMarkers.current = dataSource;
      }
      if (dataSource.name === 'videoMarkers') {
        videoMarkers.current = dataSource;
      }
      if (dataSource.name === 'locationPicker') {
        locationMarker.current = dataSource;
      }
    });

    // Remove double-click handler
    cesiumWidget.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  }, [appState, cameraStartLocation, dispatch, initialLocation.height, initialLocation.lat, initialLocation.lon, viewerRef]);

  useEffect(() => {
    if (visibleLayer) {
      updateActiveLayer(activeLayer);
      return;
    }
    if (selectedEntity && selectedEntity.polygon && selectedEntity.name) {
      updateActiveLayer(activeLayer, selectedEntity.name)
    }
  }, [selectedEntity, visibleLayer]);

  function handleSelectedEntityChange(entity) {
    if (appState === 'PICK_LOCATION') {
      return;
    }
    const viewer = viewerRef.current;
    if (!entity || !viewer) {
      return;
    }

    // If it's a country, highlight with color, otherwise clear active
    if (!visibleLayer) {
      updateActiveLayer(activeLayer, entity.name);
    }

    // Focus on entity
    if (entity.polygon && !entity.position) {
      entity = entity.entityCollection.values.find(item => item.name === entity.name && item.position);
      if (!entity) {
        return;
      }
    }
    const destination = getSelectedEntityDestination(entity);
    const { camera } = viewer.cesiumElement;
    camera.flyTo({
      destination,
      duration: 1.0
    });
    // Close all sidebars
    history.push('/');

    // Clear cesium selectedEntity to allow selecting the same project again
    if (viewer) {
      viewer.cesiumElement.selectedEntity = undefined;
    }
    setSelectedEntity(entity);
  }

  function handleLoadCountries(dataSource) {
    setupCountryEntities(dataSource);
    dataSource.zIndex = 10;
    countriesLayer.current = dataSource;
  }

  function handleLoadActiveLayer(dataSource) {
    setupActiveEntities(dataSource);
    activeLayer.current = dataSource;
  }

  const entities = useMemo(() => {
    const visibleProjects = getVisibleProjects(projects, projectFilters);
    let camera;
    if (viewerRef.current) {
      camera = viewerRef.current.cesiumElement.camera;
    }
    return visibleProjects.map((project) => <ProjectEntity project={ project } camera={ camera } />);
  }, [projects, projectFilters, viewerRef]);

  const partnerVideoMarkers = useMemo(() => {
    const visiblePartnerVideos = getVisiblePartners(partnerVideos, projectFilters);
    let camera;
    if (viewerRef.current) {
      camera = viewerRef.current.cesiumElement.camera;
    }

    return visiblePartnerVideos.map((item) => <VideoEntity video={ item } camera={ camera } />);
  }, [partnerVideos, projectFilters, viewerRef]);

  return (
    <>
      <Viewer
        ref={ viewerRef }
        scene3DOnly={ true }
        animation={ false }
        timeline={ false }
        selectionIndicator={ false }
        infoBox={ false }
        geocoder={ false }
        homeButton={ false }
        sceneModePicker={ false }
        projectionPicker={ false }
        navigationHelpButton={ false }
        fullscreenButton={ false }
        baseLayerPicker={ false }
        skyAtmosphere={ false }
        imageryProvider= { imageryProvider }
        onSelectedEntityChange={ handleSelectedEntityChange }
      >
        <ScreenSpaceCameraController
          enableTilt={ false }
          enableTranslate={ false }
          maximumZoomDistance={ 5e7 }
          minimumZoomDistance={ 1e3 }>
          { null }
        </ScreenSpaceCameraController>
        <CustomDataSource
          name="projectMarkers"
          show={ showProjects }
          // clustering={ clustering }
        >
          { entities  }
        </CustomDataSource>
        <CustomDataSource
          name="videoMarkers"
          show={ appState === 'READY' }
        >
          { partnerVideoMarkers }
        </CustomDataSource>
        <CustomDataSource
          name="locationPicker"
          show={ (appState === 'PICK_LOCATION' && !!selectedLocation) }
        >
          { selectedLocation &&
            <Entity
              position={ Cartesian3.fromDegrees(selectedLocation.lng, selectedLocation.lat) }
              billboard={ billboard }>
            </Entity>
          }
        </CustomDataSource>
        <GlobeLayers onLoadCountries={ handleLoadCountries } onLoadActiveLayer={ handleLoadActiveLayer } />
      </Viewer>
      { selectedEntity && selectedEntity.project &&
        <InfoBox
          entity={ selectedEntity }
          viewerRef={ viewerRef }
        >
          <ProjectPopup project={ projects.find(project => project.id === selectedEntity.project.id) || selectedEntity.project } onHide={ () => setSelectedEntity(null) } />
        </InfoBox>
      }
      { selectedEntity && selectedEntity.video &&
        <InfoBox
          entity={ selectedEntity }
          viewerRef={ viewerRef }
        >
          <VideoPopup video={ selectedEntity.video } onHide={ () => setSelectedEntity(null) } />
        </InfoBox>
      }
      { selectedEntity && selectedEntity.name &&
        <InfoBox
          className="country"
          entity={ selectedEntity }
          viewerRef={ viewerRef }
        >
          <CountryPopup country={ selectedEntity } onHide={ () => {
            updateActiveLayer(activeLayer);
            setSelectedEntity(null);
          } } />
        </InfoBox>
      }
    </>
  );
}
