import {
    DONATION_OPTIONS_REQUEST_FAILURE,
    DONATION_OPTIONS_REQUEST_SUCCESS,
    PROJECT_TYPES_REQUEST_FAILURE,
    PROJECT_TYPES_REQUEST_SUCCESS,
    PROJECTS_REQUEST_FAILURE,
    PROJECTS_REQUEST_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    ON_IMAGERY_LOAD,
    SET_HOME_LOCATION,
    SET_APP_STATE,
    ON_DATA_SOURCE_DISPLAY_READY
} from '../actions/actions';

// DEVELOPMENT ONLY
// const initialState = {
//     appState: 'READY',
//     initialLoadDone: true
// }

const initialState = {
    appState: 'LOADING',
    initialLoadDone: false
}

export function app(state = initialState, action) {
    switch(action.type) {
        case DONATION_OPTIONS_REQUEST_SUCCESS:
        case DONATION_OPTIONS_REQUEST_FAILURE: {
            const nextState = {
                ...state,
                donationOptionsLoaded: true
            };
            return getNextLoadedState(nextState);
        }
        case PROJECT_TYPES_REQUEST_FAILURE:
        case PROJECT_TYPES_REQUEST_SUCCESS: {
            const nextState = {
                ...state,
                projectTypesLoaded: true
            };
            return getNextLoadedState(nextState);
        }
        case PROJECTS_REQUEST_FAILURE:
        case PROJECTS_REQUEST_SUCCESS: {
            const nextState = {
                ...state,
                projectsLoaded: true
            };
            return getNextLoadedState(nextState);
        }
        case LOGIN_FAILURE:
        case LOGIN_SUCCESS: {
            const nextState = {
                ...state,
                userLoaded: true
            };
            return getNextLoadedState(nextState);
        }
        case ON_IMAGERY_LOAD: {
            const nextState = {
                ...state,
                imageryLoaded: true
            };
            return getNextLoadedState(nextState);
        }
        case ON_DATA_SOURCE_DISPLAY_READY: {
            const nextState = {
                ...state,
                dataSourceDisplayReady: true
            };
            return getNextLoadedState(nextState);
        }
        case SET_APP_STATE: {
            const { appState } = action;
            console.log('setting app state', appState);
            return {
                ...state,
                appState
            };
        }
        default: {
            return state;
        }
    }


}

function getNextLoadedState(state) {
    if (state.initialLoadDone) {
        return state;
    }
    const initialLoadDone = hasLoaded(state);
    return {
        ...state,
        appState: initialLoadDone ? 'LOADED' : 'LOADING',
        initialLoadDone
    }
}

function hasLoaded(state) {
    const {
        donationOptionsLoaded,
        projectTypesLoaded,
        projectsLoaded,
        userLoaded,
        imageryLoaded,
        dataSourceDisplayReady
    } = state;
    return donationOptionsLoaded && projectTypesLoaded && projectsLoaded && userLoaded && imageryLoaded && dataSourceDisplayReady;
}