import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { api } from '../api';

export default function ProjectUserCard({ user, onRemove }) {
  return (
    <Card className="project-card vertical-card">
        <div className="vertical-card-left">
            <div
                className="vertical-card-left-image project-user-image"
                style={{
                    backgroundImage: `url("${api.defaults.publicURL}/${user.profilePicture}")`
                }}
            >
            </div>
        </div>
        <Card.Body>
            <Card.Title>{`${user.forename} ${user.surname}`}</Card.Title>
            { user.tagline && <Card.Text>{ user.tagline }</Card.Text> }
            { onRemove &&
                <Button size="sm" variant="secondary" onClick={ onRemove } >Remove </Button>
            }
        </Card.Body>
    </Card>);
}
