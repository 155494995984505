import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { api } from '../api';
import { fetchProjects } from '../actions/projects';

export default function ConfirmApproveProject({ show, hide, project }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  async function handleApprove(projectId) {
    try {
      await api.put(`/projects/${ project.id }/approve`);
      dispatch(fetchProjects('PENDING'));
      hide();
      history.push('/');
    } catch(error) {
      console.error(error);
    }
  }

  return (
    <Modal show={ show } onHide={ hide }>
      <Modal.Header closeButton>
        <Modal.Title>{ t('confirmApproveProject.title') }</Modal.Title>
      </Modal.Header>
      <Modal.Body>{ t('confirmApproveProject.description') }</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ hide }>
          { t('confirmApproveProject.cancel') }
        </Button>
        <Button type="submit" onClick={handleApprove}>
          { t('confirmApproveProject.confirm') }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
