import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import AppLoader from './AppLoader';
import Button from 'react-bootstrap/Button';

import { updateProfile } from '../actions/auth';
import { useHistory } from 'react-router-dom';

export default function ProfileForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isFetching = useSelector(state => state.auth.isFetching);
  const [saveError, setSaveError] = useState();
  const { user } = useSelector(state => state.auth);

  const formik = useFormik({
    initialValues: {
        forename: user ? user.forename : '',
        surname: user ? user.surname : '',
        email: user ? user.email : '',
        tagline: user ? user.tagline : ''
    },
    validationSchema: Yup.object({
        forename: Yup.string()
            .required(t('errors.required')),
        surname: Yup.string()
            .required(t('errors.required')),
        email: Yup.string().email(t('errors.invalidEmail'))
            .required(t('errors.required')),
        tagline: Yup.string()
            .required(t('errors.required'))
    }),
    onSubmit: async function handleSubmit(values, { resetForm }) {
      try {
        values = omit(values, 'confirmPassword');
        await dispatch(updateProfile(values));
        history.push('/menu');
      } catch(error) {
        setSaveError(t('errors.generalError'));
      }
    }
  });

  return (
    <>
      <AppLoader
        component={ true }
        active={ isFetching }
      />
      <Form
        className="profile-form"
        noValidate
        onSubmit={ formik.handleSubmit }
      >
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>{t('profileForm.forename')}</Form.Label>
              <Form.Control
                  id="forename"
                  name="forename"
                  type="forename"
                  placeholder={t('profileForm.forename') }
                  value={ formik.values.forename }
                  onChange={ formik.handleChange }
                  onBlur={formik.handleBlur}
                  isInvalid={ formik.touched.forename && formik.errors.forename }
              />
              {formik.touched.forename && formik.errors.forename ? (
                  <Form.Control.Feedback type="invalid">
                      { formik.errors.forename }
                  </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>{t('profileForm.surname')}</Form.Label>
              <Form.Control
                  id="surname"
                  name="surname"
                  type="surname"
                  placeholder={t('profileForm.surname') }
                  value={ formik.values.surname }
                  onChange={ formik.handleChange }
                  onBlur={formik.handleBlur}
                  isInvalid={ formik.touched.surname && formik.errors.surname }
              />
              {formik.touched.surname && formik.errors.surname ? (
                  <Form.Control.Feedback type="invalid">
                      { formik.errors.surname }
                  </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
            <Form.Label>{t('profileForm.email')}</Form.Label>
            <Form.Control
                id="email"
                name="email"
                type="email"
                placeholder={t('profileForm.email') }
                value={ formik.values.email }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.email && formik.errors.email }
            />
            {formik.touched.email && formik.errors.email ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.email }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        <Form.Group>
            <Form.Label>{t('profileForm.tagline')}</Form.Label>
            <Form.Control
                id="tagline"
                name="tagline"
                type="text"
                placeholder={t('profileForm.tagline') }
                aria-describedby="taglineHelpBlock"
                value={ formik.values.tagline }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.tagline && formik.errors.tagline }
            />
            <Form.Text id="taglineHelpBlock" muted>
                { t('profileForm.taglineHelpText') }
            </Form.Text>
          {formik.touched.tagline && formik.errors.tagline ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.tagline }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        <Button
          variant="primary"
          block
          type="submit"
        >
          {t('profileForm.save')}
        </Button>
        { saveError &&
          <Alert className="mt-3" variant="danger">{ saveError }</Alert>
        }
      </Form>
    </>
    );
}
