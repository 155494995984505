import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { sortBy, filter } from 'lodash';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import './css/ProjectPeopleSelector.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, FormControl } from 'react-bootstrap';
import ProjectUserCard from './ProjectUserCard';


export default function ProjectPeopleSelector({
    users,
    name,
    value = [],
    onChange,
    error,
    isInvalid
}) {
    const { t } = useTranslation();
    const user = useSelector(state => state.auth.user);
    let selectedUsers;
    function handleSelectUser(key) {
        key = parseInt(key);
        const update = [
            ...value,
            key
        ];
        onChange({
            name,
            value: update
        });
    }

    function handleRemoveUser(id) {
        const update = value.filter(item => item !== id);
        onChange({
            name,
            value: update
        });
    }

    if (users) {
        selectedUsers = value.map((id) => users.find(user => user.id === id));
        users = users.filter(item => {
            const notLoggedInUser = !user || user.id !== item.id;
            return !value.includes(item.id) && notLoggedInUser;
        });
    }

    return (
        <div className="project-people-selector">
            <div className="mt-2 project-people-selector-heading">
                <h4>{ t('createProjectForm.projectPeople')}</h4>
                { isInvalid && error &&
                    <Alert className="mb-2" variant="danger">{ error }</Alert>
                }
                { users &&
                    <Dropdown
                        drop={ 'left' }
                        onSelect={ handleSelectUser }
                    >
                        <Dropdown.Toggle as={ AddUserToggle }  />
                        <Dropdown.Menu as={ AddUserMenu } users={ users } />
                    </Dropdown>
                }
            </div>
            { selectedUsers &&
                <div className="project-people-selected-users">
                    { selectedUsers.length === 0 &&
                        <Alert className="mb-1 mt-1" variant="info">You will see a list of users added to the project</Alert>
                    }
                    { selectedUsers.map((user) => {
                        return <ProjectUserCard user={ user } onRemove={ () => handleRemoveUser(user.id) } />
                    })}
                </div>
            }

        </div>
    )
}

const AddUserToggle = React.forwardRef(({ onClick }, ref) => {
    return (
        <Button variant="secondary" ref={ ref } onClick={ (e) => onClick(e) } >
            <FontAwesomeIcon icon={ faUserPlus} />
            <span style={{ marginLeft: '.5rem' }}>Add</span>
        </Button>
    );
});

const AddUserMenu = React.forwardRef(({ users, styles, className }, ref) => {
    const [value, setValue] = useState('');

    users = filter(users, (user) => {
        const name = `${ user.surname }, ${ user.forename}`;
        return !value || value.length < 3 || name.toLowerCase().includes(value.toLowerCase());
    });
    users = sortBy(users, ['surname', 'forename']);

    return (
        <div ref={ ref } className={ className } styles={ styles }>
            <FormControl
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Type to filter..."
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <ul className="list-unstyled" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                { users.map((user) => {
                    return <Dropdown.Item eventKey={ user.id } >{ user.surname }, { user.forename }</Dropdown.Item>
                }) }
            </ul>
        </div>
    )
})