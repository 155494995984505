import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DonationOptionSelector from './DonationOptionSelector';
import ProjectPeopleSelector from './ProjectPeopleSelector';
import { setAppState } from '../actions/app';


export default function CreateProjectForm({ formik, users, edit = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectTypes = useSelector(state => state.projectTypes.projectTypes);
  const donationOptions = useSelector(state => state.donationOptions.donationOptions);
  const selectedLocation = useSelector(state => state.globe.selectedLocation);
  const appState = useSelector(state => state.app.appState);

  function handleChange({
      name,
      value
  }) {
    formik.setTouched({
        ...formik.touched,
        [name]: true
    });
    formik.setFieldValue(name, value);
  }

  useEffect(() => {
    if (appState !== 'PICK_LOCATION') {
      return;
    }
    handleChange({
      name: 'lat',
      value: selectedLocation ? selectedLocation.lat : ''
    });
    handleChange({
      name: 'lng',
      value: selectedLocation ? selectedLocation.lng : ''
    });
  }, [ selectedLocation ]);

  return (
    <Form
      noValidate
    >
      <Form.Group>
        <Form.Label>{t('createProjectForm.projectType')}</Form.Label>
        <Form.Control
          as="select"
          name="projectTypeId"
          id="projectTypeId"
          onChange={ formik.handleChange }
          onBlur={formik.handleBlur}
          value={ formik.values.projectTypeId }
          isInvalid={ formik.touched.projectTypeId && formik.errors.projectTypeId }
        >
          <option value="">{t('createProjectForm.selectProjectType')}</option>
          {
            projectTypes && projectTypes.map((projectType) => {
              return (
                <option key={projectType.id} value={ projectType.id } >
                  {projectType.type}
                </option>);
            })
          }
        </Form.Control>
        {formik.touched.projectTypeId && formik.errors.projectTypeId ? (
          <Form.Control.Feedback type="invalid">
              { formik.errors.projectTypeId }
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>{ t('createProjectForm.name') }</Form.Label>
        <Form.Control
            id="name"
            name="name"
            type="text"
            placeholder={t('createProjectForm.name') }
            value={ formik.values.name }
            onChange={ formik.handleChange }
            onBlur={formik.handleBlur}
            isInvalid={ formik.touched.name && formik.errors.name }
        />
        {formik.touched.name && formik.errors.name ? (
            <Form.Control.Feedback type="invalid">
                { formik.errors.name }
            </Form.Control.Feedback>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>{ t('createProjectForm.description') }</Form.Label>
        <Form.Control
            id="description"
            name="description"
            as="textarea"
            placeholder={t('createProjectForm.description') }
            value={ formik.values.description }
            onChange={ formik.handleChange }
            onBlur={formik.handleBlur}
            isInvalid={ formik.touched.description && formik.errors.description }
        />
        {formik.touched.description && formik.errors.description ? (
            <Form.Control.Feedback type="invalid">
                { formik.errors.description }
            </Form.Control.Feedback>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>{ t('createProjectForm.longDescription') }</Form.Label>
        <Form.Control
            id="longDescription"
            name="longDescription"
            as="textarea"
            rows="6"
            placeholder={t('createProjectForm.longDescription') }
            value={ formik.values.longDescription }
            onChange={ formik.handleChange }
            onBlur={formik.handleBlur}
            isInvalid={ formik.touched.longDescription && formik.errors.longDescription }
        />
      {formik.touched.longDescription && formik.errors.longDescription ? (
            <Form.Control.Feedback type="invalid">
                { formik.errors.longDescription }
            </Form.Control.Feedback>
        ) : null}
      </Form.Group>
      <Form.Group>
        <Form.Label>{ t('createProjectForm.location') }</Form.Label>
        <Form.Control
            id="location"
            name="location"
            type="text"
            placeholder={t('createProjectForm.location') }
            value={ formik.values.location }
            onChange={ formik.handleChange }
            onBlur={formik.handleBlur}
            isInvalid={ formik.touched.location && formik.errors.location }
        />
        {formik.touched.location && formik.errors.location ? (
            <Form.Control.Feedback type="invalid">
                { formik.errors.location }
            </Form.Control.Feedback>
        ) : null}
      </Form.Group>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>{ t('createProjectForm.lat') }</Form.Label>
            <Form.Control
                id="lat"
                name="lat"
                type="number"
                min={-90}
                max={90}
                step={0.01}
                placeholder={t('createProjectForm.lat') }
                value={ formik.values.lat }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.lat && formik.errors.lat }
            />
            {formik.touched.lat && formik.errors.lat ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.lat }
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>{ t('createProjectForm.lng') }</Form.Label>
            <Form.Control
                id="lng"
                name="lng"
                type="number"
                min={-180}
                max={180}
                step={0.01}
                placeholder={t('createProjectForm.lng') }
                value={ formik.values.lng }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.lng && formik.errors.lng }
            />
            {formik.touched.lng && formik.errors.lng ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.lng }
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
        </Col>
      </Row>
      <Button variant="secondary" onClick={ () => dispatch(setAppState('PICK_LOCATION')) }>
        { t('createProjectForm.pickCoordinates') }
      </Button>
        <h4 className="mt-2">{ t('createProjectForm.donationOptions')}</h4>
        { donationOptions &&
          <DonationOptionSelector
            name="donationOptions"
            error={ formik.errors.donationOptions }
            value={ formik.values.donationOptions }
            donationOptions={ donationOptions }
            onChange={ handleChange }
            isInvalid={ formik.touched.donationOptions && formik.errors.donationOptions }
          />
        }
        <ProjectPeopleSelector
          name="projectUsers"
          onChange={ handleChange }
          users={ users }
          error={ formik.errors.projectUsers }
          value={ formik.values.projectUsers }
          isInvalid={ formik.touched.projectUsers && formik.errors.projectUsers }
        />
    </Form>);
}
