import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function PopupHeader({ image, onHide, className, badgeText }) {
    return (
        <div className={ `popup-header ${ className ? className : '' } ${ !badgeText ? 'no-badge' : ''}` } style={{
            backgroundImage: `url('${ image }')`
          }}>
            { badgeText &&
              <Badge
                pill
                variant="light"
              >
                { badgeText }
              </Badge>
            }
            <FontAwesomeIcon className="popup-close" onClick={ onHide } icon={ faTimes } />
        </div>
    )
}