import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

import './css/ThankYouForDonation.css';

export default function ThankYouForDonation({ show, hide }) {
  const { t } = useTranslation();

  return (
    <Modal className="thank-you-for-donation" size="sm" show={ show } onHide={ hide }>
      <Modal.Header closeButton>
        <Modal.Title>{ t('thankYouForDonation.title') }</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-between">
        <FontAwesomeIcon className="align-self-center" icon={faHeart} />
        { t('thankYouForDonation.description') }
        <FontAwesomeIcon className="align-self-center" icon={faHeart} />
      </Modal.Body>
    </Modal>
  );
}
