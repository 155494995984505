import {
    STATUSES_REQUEST_SUCCESS
} from '../actions/actions';

const initialState = {
    statuses: []
};

export function statuses(state = initialState, action) {
    switch(action.type) {
        case STATUSES_REQUEST_SUCCESS: {
            const { statuses } = action;
            return {
                ...state,
                statuses
            };
        }
        default: {
            return state;
        }
    }
}
