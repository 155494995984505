import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import Sidebar from './Sidebar';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faStamp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';

import AppLoader from './AppLoader';
import AttachmentPanel from './AttachmentPanel';
import CreateProjectForm from './CreateProjectForm';
import ConfirmApproveProject from './ConfirmApproveProject';
import ConfirmDeleteProject from './ConfirmDeleteProject';
import { api } from '../api';
import { updateProject, fetchProjectById } from '../actions/projects';

export default function SidebarEditProject({ show }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const appState = useSelector(state => state.app.appState);
  const isFetchingSingle= useSelector(state => state.projects.isFetchingSingle);
  const project= useSelector(state => state.projects.project);
  const [users, setUsers] = useState();
  const [showConfirmApproveModal, setShowConfirmApproveModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [dateTimeRange, setDateTimeRange] = useState([null, null]);
  const [ isFetchingUsers, setIsFetchingUsers ] = useState();
  const [ isSending, setIsSending ] = useState(false);
  const isFetching = useSelector(state => state.donationOptions.isFetching);
  const handleOpenConfirmApproveModal = () => setShowConfirmApproveModal(true);
  const handleHideConfirmApproveModal = () => setShowConfirmApproveModal(false);
  const handleOpenConfirmDeleteModal = () => setShowConfirmDeleteModal(true);
  const handleHideConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
  const token = localStorage.getItem('token');

  async function handleSaveMeeting() {
    try {
      const values = {
        projectId,
        from: dateTimeRange[0],
        to: dateTimeRange[1]
      };
      await api.post(`/projects/${ projectId }/meetings`, values);
      dispatch(fetchProjectById(projectId));
      setDateTimeRange([null, null]);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDeleteMeeting(projectId, meetingId) {
    try {
      await api.delete(`/projects/${ projectId }/meetings/${ meetingId }`);
      dispatch(fetchProjectById(projectId));
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectById(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (project) {
      const values = pick(project, [
        'name',
        'description',
        'longDescription',
        'location',
        'lat',
        'lng'
      ]);
      values.projectTypeId = project.typeId;
      values.donationOptions = project.projectDonationOptions.map(option => option.donationOptionId) || [];
      values.projectUsers = project.projectUsers.map(user => user.userId) || [];
      if (formik) {
        formik.setValues(values, false);
      }
    }
  }, [project]);

  const formik = useFormik({
    initialValues: {
      projectTypeId: undefined,
      name: '',
      description: '',
      longDescription: '',
      location: '',
      lat: '',
      lng: '',
      donationOptions: [],
      projectUsers: []
    },
    validationSchema: Yup.object({
      projectTypeId: Yup.number()
        .required(t('errors.required')),
      name: Yup.string()
        .required(t('errors.required')),
      description: Yup.string()
        .max(255, t('errors.maxLength') + ' ${max}')
        .required(t('errors.required')),
      longDescription: Yup.string()
        .max(65535, t('errors.maxLength') + ' ${max}'),
      location: Yup.string()
        .required(t('errors.required')),
      lat: Yup.number()
        .min(-90, t('errors.numberMin') + " ${ min }")
        .max(90, t('errors.numberMax') + " ${ max }")
        .required(t('errors.required')),
      lng: Yup.string()
        .min(-180, t('errors.numberMin') + " ${ min }")
        .max(180, t('errors.numberMax') + " ${ max }")
        .required(t('errors.required')),
      donationOptions: Yup.array()
    }),
    onSubmit: async function handleSubmit(values, { resetForm }) {
      try {
        setIsSending(true);
        const response = await api.put(`/users/me/projects/${ projectId }`, values);
        const { project } = response.data;
        dispatch(updateProject(project));
        history.push('/my-projects');
        formik.resetForm();
        setIsSending(false);
      } catch(error) {
        console.error(error);
        setIsSending(false);
      }
    }
  });

  useEffect(() => {
    async function fetchUsers() {
      try {
        setIsFetchingUsers(true);
        const response = await api.get('/users');
        const { users } = response.data;
        setUsers(users);
        setIsFetchingUsers(false);
      } catch(error) {
        console.log(error);
      }
    }
    if (show) {
      fetchUsers();
    }
  }, [show]);

  if (show && !token) {
    return <Redirect to="/menu/login" />;
  }

  return (
    <>
      <Sidebar
        open={ appState !== 'PICK_LOCATION' && show }
        pullRight={true}
        onHide={ () => formik.resetForm() }
        headerOptions={ {
          content:(
            <div className="d-flex justify-content-end">
              { user ? project ? user.role === 'ADMIN' && project.status !== 'PUBLISHED' && <Button className="mr-2" onClick={handleOpenConfirmApproveModal}>
                <FontAwesomeIcon icon={ faStamp } />
                <span className="ml-1">{ t('sidebarEditProject.approveProject') }</span>
              </Button> : null : null }
              { user ? user.role === 'ADMIN' && <Button variant="danger" onClick={handleOpenConfirmDeleteModal}>
                <FontAwesomeIcon icon={ faTrashAlt } />
                <span className="ml-1">{ t('sidebarEditProject.deleteProject') }</span>
              </Button> : null }
              <Button className="ml-2" onClick={ formik.handleSubmit }>
                <FontAwesomeIcon icon={ faPaperPlane } />
                <span className="ml-1">{ t('sidebarEditProject.updateProject') }</span>
              </Button>
            </div>
          ),
          reverse: true
        } }
        content={
          <>
            <AppLoader component={ true } active={ isFetching || isFetchingUsers || isSending || isFetchingSingle } />
            { project &&
              <AttachmentPanel project={ project } edit={ true } />
            }
            <CreateProjectForm formik={ formik } users={ users } edit={ true } />
            <h3>{t('sidebarEditProject.meetingHeading')}</h3>
            { project && project.meetings.map((meeting) => (
                <div className="d-flex justify-content-between">
                    <p className="align-self-center">
                      { moment(meeting.from).format('D.M.YYYY HH:mm (Z)') } - { moment(meeting.to).format('D.M.YYYY HH:mm (Z)') }
                    </p>
                    <Button className="align-self-center" variant="danger" onClick={ () => handleDeleteMeeting(project.id, meeting.id) }>
                        <FontAwesomeIcon icon={ faTrashAlt } />
                    </Button>
                </div>
            )) }
            <div className="d-flex justify-content-between">
              <DateTimeRangePicker
                onChange={ setDateTimeRange }
                value={ dateTimeRange } />
              <Button variant="primary" onClick={ handleSaveMeeting }>
                <FontAwesomeIcon icon={ faPlus } />
              </Button>
            </div>
          </>
        }
      />

      <ConfirmApproveProject
        show={showConfirmApproveModal}
        hide={handleHideConfirmApproveModal}
        project={project} />

      <ConfirmDeleteProject
        show={showConfirmDeleteModal}
        hide={handleHideConfirmDeleteModal}
        project={project} />
    </>
  );
}
