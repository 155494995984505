import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import AppLoader from './AppLoader';
import CheckoutForm from './CheckoutForm';
import ConfirmPayment from './ConfirmPayment';
import api from '../stripeApi';
import { gaEvent, gaSessionTiming, preventDefault } from '../utils';

import './css/Donate.css';

export default function Donate({ amount, projectName, projectDonationOptionId, onPaymentSuccess }) {
  const user = useSelector(state => state.auth.user);
  const stripePromise = api.getPublicStripeKey().then((key) => loadStripe(key));
  const [isFetchingPaymentMethods, setIsFetchingPaymentMethods] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => setShowConfirmModal(true);
  const handleHideConfirmModal = () => setShowConfirmModal(false);

  gaEvent('donate',projectDonationOptionId,amount);
  gaSessionTiming('donate',projectDonationOptionId, amount);

  async function handleGetPaymentMethods() {
    if (user) {
      setIsFetchingPaymentMethods(true);

      try {
        const methods = await api.getPaymentMethods({ userId: user.id });
        setPaymentMethods(methods);

        if (methods.length > 0) {
          handleOpenConfirmModal();
        }
      } catch (error) {
        console.error('[Donate] error:', error);
      } finally {
        setIsFetchingPaymentMethods(false);
      }
    }
  }

  async function handlePayment(e) {
    preventDefault(e);

    const metadata = { userId: user.id, projectDonationOptionId };
    const options = {
      customer: paymentMethods[0].customer,
      payment_method: paymentMethods[0].id,
      off_session: true,
      confirm: true
    };

    try {
      await api.createPaymentIntent({ ...options, amount, metadata });
      handleHideConfirmModal();
      onPaymentSuccess();
    } catch (error) {
      console.error('[CheckoutForm] error:', error);
    }
  }

  useEffect(() => {
    if (user) {
      handleGetPaymentMethods();
    }
  }, [user]);

  return (
    <>
      <AppLoader
        component={ true }
        active={ isFetchingPaymentMethods }
      />
      { !isFetchingPaymentMethods && paymentMethods.length === 0 ?
        <Elements stripe={stripePromise}>
          <CheckoutForm
            amount={amount}
            projectDonationOptionId={projectDonationOptionId}
            onPaymentSuccess={onPaymentSuccess} />
        </Elements> :
        <ConfirmPayment
          show={ showConfirmModal }
          hide={ handleHideConfirmModal }
          amount={ (amount / 100) }
          projectName={ projectName }
          onSubmit={ handlePayment } />
      }
    </>
  );
}
