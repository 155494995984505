import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import AppLoader from './AppLoader';
import Button from 'react-bootstrap/Button';

import { signup } from '../actions/auth';
import { useHistory } from 'react-router-dom';

export default function SignUpForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const isFetching = useSelector(state => state.auth.isFetching);
  const [signupError, setSignupError] = useState();

  const formik = useFormik({
    initialValues: {
        forename: '',
        surname: '',
        email: '',
        password: '',
        confirmPassword: ''
    },
    validationSchema: Yup.object({
        forename: Yup.string()
            .required(t('errors.required')),
        surname: Yup.string()
            .required(t('errors.required')),
        email: Yup.string().email(t('errors.invalidEmail'))
            .required(t('errors.required')),
        password: Yup.string()
            .required(t('errors.required')),
        confirmPassword: Yup.string()
            .required(t('errors.required'))
            .oneOf([Yup.ref('password'), null], t('errors.confirmPassword'))
    }),
    onSubmit: async function handleSubmit(values, { resetForm }) {
      try {
        values = omit(values, 'confirmPassword');
        await dispatch(signup(values));
        history.push('/menu');
      } catch(error) {
        setSignupError(t('errors.generalError'));
      }
    }
  });

  return (
    <>
      <AppLoader
        component={ true }
        active={ isFetching }
      />
      <Form
        className="signup-form"
        noValidate
        onSubmit={ formik.handleSubmit }
      >
          <Form.Group>
            <Form.Label>{t('signupForm.forename')} <FieldInfo required={ true } shownToOthers={ true } /></Form.Label>
            <Form.Control
                id="forename"
                name="forename"
                type="forename"
                placeholder={t('signupForm.forename') }
                value={ formik.values.forename }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.forename && formik.errors.forename }
            />
            {formik.touched.forename && formik.errors.forename ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.forename }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('signupForm.surname')} <FieldInfo required={ true } shownToOthers={ true } /></Form.Label>
            <Form.Control
                id="surname"
                name="surname"
                type="surname"
                placeholder={t('signupForm.surname') }
                value={ formik.values.surname }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.surname && formik.errors.surname }
            />
            {formik.touched.surname && formik.errors.surname ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.surname }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        <Form.Group>
            <Form.Label>{t('signupForm.email')} <FieldInfo required={ true } /></Form.Label>
            <Form.Control
                id="email"
                name="email"
                type="email"
                placeholder={t('signupForm.email') }
                value={ formik.values.email }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.email && formik.errors.email }
            />
            {formik.touched.email && formik.errors.email ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.email }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        <Form.Group>
            <Form.Label>{t('signupForm.tagline')} <FieldInfo required={ false } shownToOthers={ true } /></Form.Label>
            <Form.Control
                id="tagline"
                name="tagline"
                type="text"
                placeholder={t('signupForm.tagline') }
                aria-describedby="taglineHelpBlock"
                value={ formik.values.tagline }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.tagline && formik.errors.tagline }
            />
            <Form.Text id="taglineHelpBlock" muted>
                { t('signupForm.taglineHelpText') }
            </Form.Text>
          {formik.touched.tagline && formik.errors.tagline ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.tagline }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        <Form.Group>
            <Form.Label>{t('signupForm.age')} <FieldInfo required={ false } /></Form.Label>
            <Form.Control
                id="age"
                name="age"
                type="number"
                min="0"
                placeholder={t('signupForm.age') }
                aria-describedby="ageHelpBlock"
                value={ formik.values.age }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.age && formik.errors.age }
            />
          <Form.Text id="ageHelpBlock" muted>
              { t('signupForm.ageHelpText') }
          </Form.Text>
          {formik.touched.age && formik.errors.age ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.age }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('signupForm.password')} <FieldInfo required={ true } /></Form.Label>
            <Form.Control
                id="password"
                name="password"
                type="password"
                placeholder={t('signupForm.password') }
                value={ formik.values.password }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.password && formik.errors.password }
            />
            {formik.touched.password && formik.errors.password ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.password }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('signupForm.confirmPassword')} <FieldInfo required={ true } /></Form.Label>
            <Form.Control
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder={t('signupForm.confirmPassword') }
                value={ formik.values.confirmPassword }
                onChange={ formik.handleChange }
                onBlur={formik.handleBlur}
                isInvalid={ formik.touched.confirmPassword && formik.errors.confirmPassword }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <Form.Control.Feedback type="invalid">
                    { formik.errors.confirmPassword }
                </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        <Form.Check
          type="checkbox"
          className="agree-to-terms"
          id="agree-tou-pp"
          name="agree-tou-pp"
          checked={ acceptTerms }
          onChange={ () => setAcceptTerms(!acceptTerms) }
          label={ <label>I agree to the <a href="#">Terms of use</a> and <a href="#">Privacy policy</a></label> }
        />
        <Button
          disabled={ !acceptTerms }
          variant="primary"
          block
          type="submit"
        >
          {t('signupForm.signup')}
        </Button>
        { signupError &&
          <Alert className="mt-3" variant="danger">{ signupError }</Alert>
        }
      </Form>
    </>
    );
}

function FieldInfo({
  shownToOthers = false,
  required = false
}) {
  const { t } = useTranslation();
  const requiredString = required ? t('required') : t('optional');
  return (
    <span className="field-info">
      { shownToOthers && <span>{ t('shownToOthers')} </span>}
      <span>({ requiredString })</span>
    </span>
  );
}
