import React from 'react';
import {
  Cartesian3,
  SceneTransforms,
  Ellipsoid,
  EllipsoidalOccluder
} from 'cesium';

import './css/InfoBox.css';

const globeEllipsoid = Ellipsoid.WGS84;

export default class InfoBox extends React.Component {
  animationId;

  constructor() {
    super();
    this.state = {};
    this.animateInfoBox = this.animateInfoBox.bind(this);
  }

  componentDidMount() {
    this.animationId = requestAnimationFrame(this.animateInfoBox);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationId);
  }

  animateInfoBox(time) {
    const { entity, viewerRef } = this.props;
    if (entity && viewerRef.current) {
      const { cesiumElement } = viewerRef.current;
      const cameraPosition = cesiumElement.camera.position;
      const occluder = new EllipsoidalOccluder(globeEllipsoid, cameraPosition);
      const entityPosition = entity.position.getValue(cesiumElement.clock.currentTime);
      const entityIsVisible = occluder.isPointVisible(entityPosition);
      const entityWindowPosition = SceneTransforms.wgs84ToWindowCoordinates(cesiumElement.scene, entityPosition);
      this.setState({
        position: entityWindowPosition,
        isVisible: entityIsVisible
      });
    }
    this.animationId = requestAnimationFrame(this.animateInfoBox);
  }

  render() {
    const {
      position,
      isVisible
    } = this.state;

    if (
      !position
      || !isVisible
    ) {
      return null;
    }

    return (
      <div className="infobox-wrapper">
        <div
          className={ `infobox ${ this.props.className ? this.props.className : '' }`}
          style={{
            bottom: window.innerHeight - position.y,
            left: position.x
          }}
        >
          <div
            className="infobox-arrow"
          />
          { this.props.children }
        </div>
      </div>
    );
  }
}
