import hexRgb from 'hex-rgb';
import ReactGA from 'react-ga';
import { api } from './api';
import { useState, useEffect } from 'react';


export var trackingVars = {
    projectName: '',
    countryName: ''
}

export function isTouchDevice() {
  return (window.navigator.msMaxTouchPoints || 'ontouchstart' in document);
}

export function preventDefault(e) {
  return e.evt ? e.evt.preventDefault() : e.preventDefault();
}

export function stripTrailingSlashes(s) {
  return s.replace(/\/+$/, '');
}

export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getRGBByValue(value, colors) {
  const { color } = colors
    .filter(color => value >= color.limit)
    .sort((a, b) => b.limit - a.limit)[0];
  const rgb = hexRgb(color, { format: 'array'}).slice(0, 3);
  return rgb.map(color => color / 255);
}

export function parseNum(string) {
  return +string.replace(/[^.\d]/g, '');
}

export function getCentroid(points) {
  const accumulatedPoints = points.reduce((acc, curr) => {
    return {
      x: acc.x + curr.x,
      y: acc.y + curr.y,
      z: acc.z + curr.z
    }
  }, {
    x: 0,
    y: 0,
    z: 0
  });

  accumulatedPoints.x /= points.length;
  accumulatedPoints.y /= points.length;
  accumulatedPoints.z /= points.length;

  return accumulatedPoints;
}

export function gaEvent(cat,act,lab) {
    ReactGA.event(
	  {
	      category: cat,
	      action: act,
	      label: lab,
	      nonInteraction: false
	  }
    );
}

export async function gaSessionTiming(cat, variab, name) {
  var timeSincePageLoad = 0;
  if (window.performance) {
      timeSincePageLoad = Math.round(performance.now());
      ReactGA.timing(
	  {
	      category: cat,
	      variable: variab,
	      label: name,
	      value: timeSincePageLoad // ms
          }
      );
  }
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var hours = new Date().getHours(); //Current Hours
    var min = new Date().getMinutes(); //Current Minutes
    var sec = new Date().getSeconds(); //Current Seconds
    var now = date + '/' + month + '/' + year
	+ ' ' + hours + ':' + min + ':' + sec ;

/*
  var actionData = {
      apicall: cat + " " + variab + " " + name,
      created_at: now,
      served: timeSincePageLoad
  }
  const response = await api.post('/actions', actionData);
*/
}

export async function gaEventTiming(cat, variab, name) {
  var timeSinceLastMark = 0;
  if (window.performance) {
      performance.measure("actiondelay",'gatimer');
      var myentry = performance.getEntriesByName("actiondelay");
      timeSinceLastMark = myentry[0].duration;
      ReactGA.timing(
	  {
	      category: cat,
	      variable: variab,
	      label: name,
	      value: timeSinceLastMark // ms
          }
      );
      performance.clearMarks();
      performance.clearMeasures();
      performance.mark('gatimer');
  }
  var date = new Date().getDate(); //Current Date
  var month = new Date().getMonth() + 1; //Current Month
  var year = new Date().getFullYear(); //Current Year
  var hours = new Date().getHours(); //Current Hours
  var min = new Date().getMinutes(); //Current Minutes
  var sec = new Date().getSeconds(); //Current Seconds
  var now = year+ '/' + date + '/' + month +
      + ' ' + hours + ':' + min + ':' + sec ;

/*  var actionData = {
      apicall: cat + " " + variab + " " + name,
      created_at: now,
      served: timeSinceLastMark
  }
  const response = await api.post('/actions', actionData);
*/
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
      const handler = setTimeout(() => {
          setDebouncedValue(value);
      }, delay);

      return () => {
          clearTimeout(handler);
      };
  }, [value, delay]);
  return debouncedValue;
}
