import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { gaEvent, gaEventTiming, gaSessionTiming } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar';
import { CSSTransition } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import SingleProject from './SingleProject';
import ThankYouForDonation from './ThankYouForDonation';
import AppLoader from './AppLoader';
import { api } from '../api';

export default function SidebarProject({ show }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [ project, setProject ] = useState();
  const [ isFetching, setIsFetching ] = useState();
  const focusedProject = useSelector(state => state.globe.focusedProject);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    async function getProject() {
      try {
        setIsFetching(true);
        const url = `projects/${projectId}`;
        const response = await api.get(url);
        const { project } = response.data;
        setProject(project);
        setIsFetching(false);
      } catch(error) {
        console.log(error);
        setIsFetching(false);
      }
    }
    if (projectId && showThankYou === false) {
      getProject();
    }
  }, [projectId, showThankYou]);

  useEffect(() => {
    if (show && !focusedProject) {
      dispatch({
        type: 'FOCUS',
        payload: project
      });
    }
  }, [project, show]);

  if (focusedProject) {
      gaEvent('user','projectview',focusedProject.name);
      gaEventTiming('user','projectview',focusedProject.name);
  }

  return (
    <>
      <Sidebar
        headerOptions={{
          content: project ? <h4 style={{ fontWeight: '400' }}>{ project.name }</h4> : '',
          search: true,
          favourite: false,
          reverse: true
        }}
        open={ show }
        pullRight={true}
        content={
          <>
            <AppLoader component={ true } active={ isFetching } />
            <CSSTransition
              in={ show && !!project }
              timeout={ 300 }
              classNames="fade"
              onExited={ () => setProject(null) }
            >
              <SingleProject
                project={ project }
                onPaymentSuccess={() => {
                  if (projectId) {
                    setShowThankYou(true);
                  }
                }} />
            </CSSTransition>
          </>
        }
      />

      <ThankYouForDonation
        show={ showThankYou }
        hide={ () => setShowThankYou(false) } />
    </>
  );
}
