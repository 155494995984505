import {
    SET_ACTIVE_STATUS,
    SET_PAYMENT_SUCCESS,
    SET_PROCESSING_PAYMENT,
    SET_SHOW_DETAILS
} from '../actions/actions';

const initialState = {
    activeStatus: 'PUBLISHED', // Non-admins should only see published projects
    paymentSuccess: false,
    processingPayment: false,
    showDetails: false
};

export function ui(state = initialState, action) {
    switch(action.type) {
        case SET_ACTIVE_STATUS: {
            const { activeStatus } = action;
            return {
                ...state,
                activeStatus
            };
        }
        case SET_PAYMENT_SUCCESS: {
            const { paymentSuccess } = action;
            return {
                ...state,
                paymentSuccess
            };
        }
        case SET_PROCESSING_PAYMENT: {
            const { processingPayment } = action;
            return {
                ...state,
                processingPayment
            }
        }
        case SET_SHOW_DETAILS: {
            const { showDetails } = action;
            return {
                ...state,
                showDetails
            }
        }
        default: {
            return state;
        }
    }
}
