import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { isTouchDevice } from '../utils';

import './css/SwipeableButton.css';

const mapStateToProps = (state, ownProps = {}) => ({
  ...ownProps,
  processingPayment: state.ui.processingPayment
});

export default connect(mapStateToProps)(class SwipeableButton extends Component {
  state = {}

  constructor(props) {
    super(props);

    this.slider = createRef();
    this.container = createRef();
  }

  componentDidMount() {
    if (isTouchDevice()) {
      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('touchend', this.stopDrag);
    } else {
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    }
    this.containerWidth = Math.ceil(this.container.current.clientWidth / 2);
    this.slider.current.style.left = `${this.containerWidth}px`;
  }

  onDrag = (e) => {
    if (this.unmounted || this.state.processingPayment) return;

    if (this.isDragging) {
      if (isTouchDevice()) {
        this.sliderLeft = Math.min(Math.max(0, e.touches[0].clientX - this.startX), this.containerWidth);
      } else {
        this.sliderLeft = Math.min(Math.max(0, e.clientX - this.startX), this.containerWidth);
      }
      this.updateSliderStyle();
    }
  }

  updateSliderStyle = () => {
    if (this.unmounted || this.state.processingPayment) return;
    this.slider.current.style.left = `${this.containerWidth + this.sliderLeft}px`;
  }

  stopDrag = () => {
    if (this.unmounted || this.state.processingPayment) return;
    if (this.isDragging) {
      this.isDragging = false;
      if (this.sliderLeft > this.containerWidth * 0.9) {
        this.sliderLeft = this.containerWidth;
        if (this.props.onSwiped) {
          this.props.onSwiped();
          this.onSwiped();
        }
      } else {
        this.sliderLeft = 0;
        if (this.props.onFailure) {
          this.props.onFailure();
        }
      }
      this.updateSliderStyle();
    }
  }

  startDrag = (e) => {
    if (this.unmounted || this.state.processingPayment) return;
    this.isDragging = true;
    if (isTouchDevice()) {
      this.startX = e.touches[0].clientX;
    } else {
      this.startX = e.clientX;
    }
  }

  onSwiped = () => {
    this.container.current.style.width = `${this.container.current.clientWidth}px`;
    this.setState({ processingPayment: true });
  }

  onSuccess = () => {
    if (this.unmounted) return;

    this.setState({ processingPayment: false }, () => {
      this.sliderLeft = 0;
      this.updateSliderStyle();
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    return (
      <div className="ReactSwipeButton">
        <div className="rsbContainer" ref={this.container}>
          <div className="rsbcSlider"
            ref={this.slider}
            onMouseDown={this.startDrag}
            onTouchStart={this.startDrag}>
            <span className="rsbcSliderText">{this.props.activeText}</span>
          </div>
          <div className="rsbcText">{this.props.text}</div>
        </div>
      </div>
    );
  }
})
