import {
    Cartesian2,
    Cartesian3,
    Color,
    EasingFunction,
    Ellipsoid,
    Math,
    NearFarScalar,
    PolygonGeometry,
    Rectangle
} from 'cesium';

// 'this' must be set on call time from globe component
export function updateProjectMarkerVisibility() {
    if (this.projectMarkers.current) {
      const { values } = this.projectMarkers.current.entities;

      values.forEach((marker) => {
        const markerPosition = marker.position.getValue(this.clock.currentTime);
        marker.billboard.show = getBillboardVisibility(markerPosition, this.camera, this.clock);
        marker.billboard.eyeOffset = getBillboardEyeOffset(this.camera);
      });
    }
}

// 'this' must be set on call time from globe component
export function updateVideoMarkerVisibility() {
    if (this.videoMarkers.current) {
      const { values } = this.videoMarkers.current.entities;

      values.forEach((marker) => {
        const markerPosition = marker.position.getValue(this.clock.currentTime);
        marker.billboard.show = getBillboardVisibility(markerPosition, this.camera, this.clock);
        marker.billboard.eyeOffset = getBillboardEyeOffset(this.camera);
      });
    }
}

// 'this' must be set on call time from globe component
export function updateCountryLabelVisibility() {
    if (this.countriesLayer.current) {
      const { values } = this.countriesLayer.current.entities;

      // Compare label distances to camera to origo distance
      const {
        position
      } = this.camera;
      const cameraDistanceFromOrigo = Cartesian3.distance(position, new Cartesian3(0, 0, 0));
      values.forEach((entity) => {
        if (entity.position && entity.label) {
          const entityPosition = entity.position.getValue(this.clock.currentTime);
          if (cameraDistanceFromOrigo - Ellipsoid.WGS84.maximumRadius / 1.3 > Cartesian3.distance(position, entityPosition)) {
            entity.label.show = true;
          } else {
            entity.label.show = false;
          }
          const labelEyeOffsetZ = (cameraDistanceFromOrigo - Ellipsoid.WGS84.maximumRadius) / -4;
          entity.label.eyeOffset = new Cartesian3(0, 0, labelEyeOffsetZ);
        }
      });
    }
  }

export function getBillboardVisibility(billboardPosition, camera) {
    const {
      position
    } = camera;
    const cameraDistanceFromOrigo = Cartesian3.distance(position, new Cartesian3(0, 0, 0));
    if (cameraDistanceFromOrigo > Cartesian3.distance(position, billboardPosition)) {
      return true;
    } else {
      return false;
    }
}

export function getBillboardEyeOffset(camera) {
    const {
      position
    } = camera;
    const cameraDistanceFromOrigo = Cartesian3.distance(position, new Cartesian3(0, 0, 0));
    const markerEyeOffsetZ = (cameraDistanceFromOrigo - Ellipsoid.WGS84.maximumRadius) / -4;
    return new Cartesian3(0, 0, markerEyeOffsetZ);
}

// 'this' is set with bind() from globe
export function handleCanvasMouseDown(e) {
    // Parse x & y to int because click event seems to use integers
    this.current = new Cartesian2(parseInt(e.clientX), parseInt(e.clientY));
}

// 'this' is set with bind() from globe
export function resolveClickLocation(e){
    const { scene, camera } = this.viewerRef.current.cesiumElement;
    // Parse x & y to int because click event seems to use integers
    const mousePosition = new Cartesian2(parseInt(e.clientX), parseInt(e.clientY));
    if (this.onMouseDownLocation && !mousePosition.equals(this.onMouseDownLocation.current)) {
      return;
    }
    const ellipsoid = scene.globe.ellipsoid;
    const cartesian = camera.pickEllipsoid(mousePosition, ellipsoid);
    if (cartesian) {
      const cartographic = ellipsoid.cartesianToCartographic(cartesian);
      const lng = Math.toDegrees(cartographic.longitude);
      const lat = Math.toDegrees(cartographic.latitude);
      return {
        lat,
        lng
      };
    } else {
      console.warn('[Globe] Nothing was picked at canvas click handler');
    }
}

export function cameraFlyPath({
    camera,
    path,
    cb,
    projectMarkers,
    countriesLayer,
    onSelectEntity
}) {
    path.reverse();
    const flyToFunctions = path.reduce((fnArray, node) => {
      const { lon, lat, height, easing, selectedEntity, ...rest } = node;
      const flightFunction = () => {
        if (selectedEntity && selectedEntity.type === 'project') {
          const introMarkers = projectMarkers.current.entities.values;
          const entity = introMarkers.find(marker => marker.project.id === selectedEntity.id);
          if (entity) {
            onSelectEntity(entity);
          }
        } else if (selectedEntity && selectedEntity.type === 'country') {
          const countries = countriesLayer.current.entities.values;
          const entity = countries.find(country => country.name === selectedEntity.name);
          if (entity) {
            onSelectEntity(entity);
          }
        } else if (selectedEntity === null) {
          onSelectEntity(null);
        }
        camera.flyTo({
          destination: Cartesian3.fromDegrees(lon, lat, height),
          easingFunction: EasingFunction[easing],
          ...rest,
          complete: fnArray.length === 0 ? cb : fnArray[0]
        });
      }
      const updatedArray = [
        flightFunction,
        ...fnArray
      ];
      return updatedArray;
    }, [])

    flyToFunctions[0]();
}

export function setupCountryEntities(dataSource) {
    const {
        values
    } = dataSource.entities;

    values.forEach(entity => {
        if (entity.label) {
            entity.label.translucencyByDistance = new NearFarScalar(9e6, 1.0, 15e6, 0.0);
            entity.label.scaleByDistance = new NearFarScalar(2e6, 3.0, 6e6, 1.0);
        }
    });
}

export function setupActiveEntities(dataSource) {
  const {
      values
  } = dataSource.entities;

  values.forEach(entity => {
      if (entity.polygon) {
          entity.polygon.material = new Color(0.65, 0.88, 0.26, 0.8);
      }
      entity.show = false;
  });
}

export function getSelectedEntityDestination(entity) {
    const cameraHeight = 0.7e7;
    const latitudeOffset = 15;
    if (entity.project) {
        return Cartesian3.fromDegrees(entity.project.lng, entity.project.lat + latitudeOffset, cameraHeight);
    } else if (entity.video) {
        return Cartesian3.fromDegrees(entity.video.lng, entity.video.lat + latitudeOffset, cameraHeight);
    } else if (entity.polygon) {
        const polygonHierarchy = entity.polygon.hierarchy.valueOf();
        const rectangle = PolygonGeometry.computeRectangle({
            polygonHierarchy
        });
        const center = Rectangle.center(rectangle);
        return Cartesian3.fromDegrees(Math.toDegrees(center.longitude), Math.toDegrees(center.latitude) + latitudeOffset, cameraHeight);;
    }
}

export function clearActiveLayer(activeLayer) {
  const {
    values
  } = activeLayer.current.entities;
  values.forEach(entity => {
    entity.show = false;
  });
}

export function updateActiveLayer(activeLayer, name) {
  if (!activeLayer.current) {
    return;
  }
  if (!name) {
    clearActiveLayer(activeLayer);
  }
  const {
    values
  } = activeLayer.current.entities;
  values.forEach(entity => {
    if (entity.polygon) {
      entity.show = entity.name === name;
    }
  });
}