import {
  STATUSES_REQUEST,
  STATUSES_REQUEST_SUCCESS,
  STATUSES_REQUEST_FAILURE
} from './actions';
import { api } from '../api';

export function fetchStatuses() {
    return async (dispatch) => {
        const url = 'statuses';
        dispatch(onFetchStatuses());
        try {
            const response = await api.get(url);
            const { statuses } = response.data;
            dispatch(onFetchStatusesSuccess(statuses));
        } catch(error) {
            dispatch(onFetchStatusesFailure(error));
        }
    };
}

function onFetchStatuses() {
    console.log('[onFetchStatuses]');
    return {
        type: STATUSES_REQUEST
    };
}

function onFetchStatusesSuccess(statuses) {
    console.log('[onFetchStatusesSuccess]', statuses);
    return {
        type: STATUSES_REQUEST_SUCCESS,
        statuses
    };
}

function onFetchStatusesFailure(error) {
    console.log('[onFetchStatusesFailure]', error);
    return {
        type: STATUSES_REQUEST_FAILURE,
        error
    };
}
