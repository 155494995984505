import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ConfirmPayment({ show, hide, amount, projectName, onSubmit }) {
  const { t } = useTranslation();

  return (
    <Modal show={ show } onHide={ hide }>
      <Modal.Header closeButton>
        <Modal.Title>{ t('confirmPayment.title') }</Modal.Title>
      </Modal.Header>
      <Modal.Body>{ t('confirmPayment.description', { amount, projectName }) }</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ hide }>
          { t('confirmPayment.cancel') }
        </Button>
        <Button variant="primary" type="submit" onClick={ onSubmit }>
          { t('confirmPayment.confirm') }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
