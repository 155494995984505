import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { sortBy } from 'lodash';

import './css/AttachmentPanel.css';
import Badge from 'react-bootstrap/esm/Badge';
import { api } from '../api';
import AppLoader from './AppLoader';
import vimeo from '../vimeoUtils';
import { VideoUploadModal } from './VideoUploadModal';
import { useDispatch } from 'react-redux';
import { fetchProjects } from '../actions/projects';

const TAG = '[AttachmentPanel]';

const acceptedVideoTypes = [
    'video/quicktime',
    'video/mp4',
    'video/x-ms-wmv',
    'video/x-msvideo',
    'video/x-flv'
];

const acceptedImageTypes = [
    'image/*'
];

const acceptedFileTypes = acceptedImageTypes.concat(acceptedVideoTypes);

export default function AttachmentPanel({
    edit = false,
    project
}) {
    const fileInputRef = useRef();
    const fileFormRef = useRef();
    const { t } = useTranslation();
    const [ error, setError ] = useState('');
    const [ videoUploadError, setVideoUploadError ] = useState(false);
    const [ videoUploadProgress, setVideoUploadProgress ] = useState(0);
    const [ showVideoUploadModal, setShowVideoUploadModal ] = useState(false);
    const [ videoUploadComplete, setVideoUploadComplete ] = useState(false);
    const [ isFetching, setIsFetching ] = useState(false);
    const [ attachments, setAttachments ] = useState([]);
    const [ coverImage, setCoverImage ] = useState();
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();
    const upload = useRef();

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    async function handleChange(event) {
        if (!(fileFormRef.current && fileInputRef.current)) {
            return;
        }
        const file = fileInputRef.current.files[0];
        if (!file) {
            return;
        }
        let insertedAttachments;
        if (acceptedVideoTypes.includes(file.type)) {
            try {
                setShowVideoUploadModal(true);
                const reader = new FileReader();
                reader.onload = async () => {
                    let videoData;
                    try {
                        const response = await api.post(`/projects/${ project.id }/videos`, {
                            fileSize: reader.result.byteLength,
                            name: project.name
                        });
                        videoData = response.data;
                    } catch(error) {
                        setVideoUploadError(true);
                    }
                    if (!videoData) {
                        return;
                    }
                    setAttachments([
                        ...attachments,
                        videoData
                    ]);
                    const tusUpload = vimeo.upload({
                        file,
                        vimeoId: videoData.vimeoId,
                        uploadUrl: videoData.uploadLink,
                        onSuccess: () => {
                            setVideoUploadComplete(true);
                        },
                        onError: () => {
                            setVideoUploadError(true);
                            handleDeleteAttachment(videoData.id);
                        },
                        onProgress: (percentage) => {
                            setVideoUploadProgress(percentage);
                        }
                    });
                    tusUpload.attachmentId = videoData.id;
                    upload.current = tusUpload;
                };
                reader.onerror = () => {
                    setError(t('errors.uploadVideo'));
                    setShowVideoUploadModal(false);
                };
                reader.readAsArrayBuffer(file);

            } catch(error) {
                setError(t('errors.uploadVideo'));
            }
        } else {
            try {
                const formData = new FormData(fileFormRef.current);
                const response = await api.post(`/projects/${ project.id }/attachments`, formData);
                insertedAttachments = response.data.attachments;
                if (insertedAttachments) {
                    setAttachments([
                        ...attachments,
                        ...insertedAttachments
                    ]);
                }
            } catch(error) {
                setError(t('errors.uploadImage'));
            }
        }
        resetFileInput();
    }

    useEffect(() => {
        if (attachments.length > 0) {
            setIndex(attachments.length-1);
        } else {
            setIndex(0);
        }
    }, [attachments])

    useEffect(() => {
        setAttachments(project.attachments || []);
        setCoverImage(project.coverImageId);
        setError('');
    }, [project])

    async function handleSetCoverImage(attachmentId) {
        try {
            await api.put(`/projects/${ project.id}/cover`, {
                cover_image: attachmentId
            });
            dispatch(fetchProjects('PUBLISHED'));
            setCoverImage(attachmentId);
        } catch(error) {
            setError(t('errors.setCoverImage'));
        }
    }

    async function handleDeleteAttachment(attachmentId) {
        try {
            setIsFetching(true);
            await api.delete(`/projects/${ project.id }/attachments/${ attachmentId }`);
            setAttachments(attachments.filter(item => item.id !== attachmentId));
            dispatch(fetchProjects('PUBLISHED'));
            setIsFetching(false);
        } catch(error) {
            setError(t('errors.deleteMedia'));
            setIsFetching(false);
        }
    }

    function resetVideoUpload() {
        setShowVideoUploadModal(false);
        setVideoUploadComplete(false);
        setVideoUploadError(false);
        setVideoUploadProgress(0);
        upload.current = null;
    }

    function handleFinishUpload() {
        resetVideoUpload();
    }

    function resetFileInput() {
        fileInputRef.current.value = null;
    }

    async function handleAbortVideoUpload() {
        if (upload.current) {
            upload.current.abort();
            const { attachmentId } = upload.current;
            handleDeleteAttachment(attachmentId);
            handleFinishUpload();
        }
    }

    // console.log('attachments', attachments);
    // console.log('cover', coverImage);

    const visibleAttachments = sortBy(attachments, 'id');

    return (
        <div className="attachment-panel">
            <AppLoader component={ true } active={ isFetching } />
            <Carousel controls={ attachments.length > 1 } interval={ null } indicators={ false } activeIndex={index} onSelect={handleSelect}>
                { visibleAttachments.length > 0 ?
                    visibleAttachments.map(attachment => {
                        if (attachment.vimeoId) {
                            return  (
                                <Carousel.Item className="attachment-item video">
                                    { attachment.id === coverImage &&
                                        <Badge className="attachment-badge" pill>Cover video</Badge>
                                    }
                                    <iframe src={ 'https://player.vimeo.com/video/' + attachment.vimeoId } frameborder="0" allow="fullscreen;"></iframe>
                                </Carousel.Item>
                            );
                        }
                        return  (
                            <Carousel.Item className="attachment-item" style={{
                                backgroundImage: `url('${ api.defaults.publicURL }/${ attachment.path }')`
                            }}>
                                { attachment.id === coverImage &&
                                    <Badge className="attachment-badge" pill>Cover image</Badge>
                                }
                            </Carousel.Item>
                        );
                    }) :
                    (
                        <Carousel.Item className="attachment-info">
                            <h3>Project media</h3>
                            { edit ?
                                <p>You can add a number of project images and videos and select a project cover.</p> :
                                <p>This project has no media.</p>
                            }
                        </Carousel.Item>
                    )
                }
            </Carousel>
            { edit && <div className="attachment-panel-actions">
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => fileInputRef.current.click()}
                >
                    Upload
                </Button>
                { visibleAttachments[index] && visibleAttachments[index].id !== coverImage &&
                    <Button variant="secondary" size="sm" onClick={ () => handleSetCoverImage(visibleAttachments[index].id) }>Set as cover</Button>
                }
                { visibleAttachments[index] &&
                    <Button variant="danger" size="sm" onClick={ () => handleDeleteAttachment(visibleAttachments[index].id) } >Delete</Button>
                }
            </div> }
            { error &&
                <Alert className="mt-2" variant="danger" dismissible onClose={ () => setError('') }>{ error }</Alert>
            }
            { edit && <Form
                style={{ display: 'none' }}
                ref={fileFormRef}
            >
                <Form.Control name="project_id" value={ project.id } />
                <Form.File
                    name="attachment-upload"
                    ref={fileInputRef}
                    accept={ acceptedFileTypes.toString() }
                    onChange={handleChange}
                />
            </Form> }
            <VideoUploadModal
                progress={ videoUploadProgress }
                show={ showVideoUploadModal }
                onHide={ handleFinishUpload }
                complete={ videoUploadComplete }
                error={ videoUploadError }
                onPause={ () => {
                    if (upload.current) {
                        upload.current.abort();
                    }
                }}
                onAbort={ handleAbortVideoUpload }
                onResume={() => {
                    if (upload.current) {
                        upload.current.start();
                    }
                }}
            />
        </div>
    );
}
