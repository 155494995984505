import {
    ADD_PROJECT,
    PROJECTS_REQUEST,
    SINGLE_PROJECT_REQUEST,
    PROJECTS_REQUEST_SUCCESS,
    SINGLE_PROJECT_REQUEST_SUCCESS,
    SINGLE_PROJECT_REQUEST_FAILURE,
    PROJECTS_REQUEST_FAILURE,
    MY_PROJECTS_REQUEST,
    MY_PROJECTS_REQUEST_SUCCESS,
    MY_PROJECTS_REQUEST_FAILURE,
    CLEAR_SINGLE_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT
} from '../actions/actions';

import unicefVideosJSON from '../datasets/unicef_videos.json';

// TODO: Use Unicef's actual id from the database as partner id
const unicefVideos = unicefVideosJSON.map((item) => ({ partnerId: 2, ...item }));
// Only reading and rendering is done, so simulated auto increment id suffices
const partnerVideos = unicefVideos.map((item, i) => ({ id: (i+1), ...item }));
const initialState = {
    partnerVideos,
    project: undefined,
    projects: undefined,
    myProjects: undefined,
    isFetchingSingle: false,
    isFetchingProjects: false,
    isFetchingMyProjects: false
};

export function projects(state = initialState, action) {
    switch(action.type) {
        case SINGLE_PROJECT_REQUEST: {
            return {
                ...state,
                isFetchingSingle: true
            }
        }
        case SINGLE_PROJECT_REQUEST_SUCCESS: {
            const { project } = action;
            return {
                ...state,
                project,
                isFetchingSingle: false
            };
        }
        case SINGLE_PROJECT_REQUEST_FAILURE: {
            return {
                ...state,
                isFetchingSingle: false
            }
        }
        case PROJECTS_REQUEST: {
            return {
                ...state,
                isFetchingProjects: true
            }
        }
        case PROJECTS_REQUEST_SUCCESS: {
            const { projects } = action;
            return {
                ...state,
                projects,
                isFetchingProjects: false
            };
        }
        case PROJECTS_REQUEST_FAILURE: {
            return {
                ...state,
                isFetchingProjects: false
            }
        }
        case MY_PROJECTS_REQUEST: {
            return {
                ...state,
                isFetchingMyProjects: true
            }
        }
        case MY_PROJECTS_REQUEST_SUCCESS: {
            const { myProjects } = action;
            return {
                ...state,
                myProjects,
                isFetchingMyProjects: false
            };
        }
        case MY_PROJECTS_REQUEST_FAILURE: {
            return {
                ...state,
                isFetchingMyProjects: false
            }
        }
        case ADD_PROJECT: {
            const { project } = action;
            return {
                ...state,
                projects: [
                    ...state.projects,
                    project
                ]
            };
        }
        case UPDATE_PROJECT: {
            const { project } = action;
            console.log('UPDATE_PROJECT', project);
            let projects = state.projects.filter(item => item.id !== project.id);
            projects = [
                ...projects,
                project
            ];
            return {
                ...state,
                projects
            };
        }
        case DELETE_PROJECT: {
            const { projectId } = action;
            console.log('DELETE_PROJECT', projectId);
            const projects = state.projects.filter(item => item.id !== projectId);
            return {
                ...state,
                projects
            };
        }
        case CLEAR_SINGLE_PROJECT: {
            return {
                ...state,
                project: undefined
            }
        }
         default: {
            return state;
        }
    }
}

export function getVisiblePartners(partnerVideos = [], { projectTypePartnerIds }) {
    const filteredPartners = partnerVideos.filter((item) => {
        const partnerIdMatch = projectTypePartnerIds.includes(item.partnerId);
        return partnerIdMatch;
    });
    return filteredPartners;
}

export function getVisibleProjects(projects = [], { projectTypeIds, string }) {
    const searchString = string ? string.toLowerCase() : '';
    const filteredProjects = projects.filter((project) => {
        const projectTypeIdMatch = projectTypeIds.includes(project.typeId);
        const stringMatch = !string ||
          (
            project.name.toLowerCase().includes(searchString) ||
            project.description.toString().includes(searchString) ||
            project.longDescription.toString().includes(searchString)
          );
        return projectTypeIdMatch && stringMatch;
    });
    return filteredProjects;
}
