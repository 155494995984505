import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { fetchMyProjects } from '../actions/projects';
import ProjectCard from './ProjectCard';
import Sidebar from './Sidebar';

import './css/SidebarMyProjects.css';
import AppLoader from './AppLoader';
import { useHistory } from 'react-router-dom';

export default function SidebarMyProjects({ show, onHide }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const myProjects= useSelector(state => state.projects.myProjects);
  const isFetchingMyProjects= useSelector(state => state.projects.isFetchingMyProjects);
  const handleEdit = (projectId) => {
      history.push(`/edit-project/${ projectId }`);
  };

  useEffect(() => {
    if (show) {
      dispatch(fetchMyProjects());
    }
  }, [show]);

  let sidebarContent = null;
  if (show) {
    sidebarContent =
      <>
        <AppLoader component={ true } active={ isFetchingMyProjects } />
        {
          myProjects && myProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              actions={
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={ () => handleEdit(project.id) }
                >
                  <FontAwesomeIcon icon={faPen} /> { t('sidebarMyProjects.edit') }
                </Button>
              }
            />))
        }
      </>
    ;
  }

  return (
    <Sidebar
      open={ show }
      pullRight={true}
      headerOptions={{
        title: t('sidebarMyProjects.title'),
        reverse: true,
        content: (
          <h4 style={{ fontWeight: '400' }}>{ t('sidebarMyProjects.title') }</h4>
        )
      }}
      content={ sidebarContent }
    />
  );
}
