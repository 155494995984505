import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import { setVisibleLayer } from '../../../actions/globe';

export default function OurWorldListItem({ layer }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visibleLayer = useSelector(state => state.globe.visibleLayer);
  const showCountries = useSelector(state => state.globe.showCountries);

  function handleToggleLayer(layerName) {
    if (visibleLayer === layerName) {
      dispatch(setVisibleLayer({
        visibleLayer: null
      }));
    } else {
      dispatch(setVisibleLayer({
        visibleLayer: layerName
      }));
    }

    // This is when multiple layers can be visible at once
    // if (visibleLayers.includes(layerName)) {
    //   const updatedLayers = visibleLayers.filter(layer => layer !== layerName);
    //   dispatch(setVisibleLayers(updatedLayers));
    // } else {
    //   dispatch(setVisibleLayers([
    //     ...visibleLayers,
    //     layerName
    //   ]));
    // }
  }

  function handleToggleCountries() {
    dispatch(setVisibleLayer({
      showCountries: !showCountries
    }));
  }

  if (layer) {
    return (
      <ListGroup.Item
        key={ layer.name }
        onClick={ () => handleToggleLayer(layer.name) }>
        <Row className="justify-content-between" noGutters>
          { layer.label }
          <div className={`list-group-item-checkbox ${layer.name === visibleLayer && 'active'}`}>
            {
              layer.name === visibleLayer ?
                <FontAwesomeIcon icon={faCheckCircle} /> :
                <FontAwesomeIcon icon={faCircle} />
            }
          </div>
        </Row>
      </ListGroup.Item>
    );
  } else {
    return (
      <ListGroup.Item
        key="show-countries"
        onClick={ handleToggleCountries }>
        <Row className="justify-content-between" noGutters>
          { t('navbar.showCountries') }
          <div className={`list-group-item-checkbox ${showCountries && 'active'}`}>
            {
              showCountries ?
                <FontAwesomeIcon icon={faCheckSquare} /> :
                <FontAwesomeIcon icon={faSquare} />
            }
          </div>
        </Row>
      </ListGroup.Item>
    );
  }
}
