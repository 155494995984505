import React from 'react';
import ReactGA from 'react-ga';
import { gaEvent, gaEventTiming, gaSessionTiming, trackingVars } from '../utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import statistics from '../datasets/countries_datasheet_2.json';
import { Button } from 'react-bootstrap';
import './css/Popup.css';
import PopupHeader from './PopupHeader';


export default function CountryPopup({ country, onHide }) {
    const { t } = useTranslation();
    const visibleLayer = useSelector(state => state.globe.visibleLayer);

    const data = statistics.find(item => item.name.trim().toLowerCase() === country.name.trim().toLowerCase());
    const countryName = country.name;
    let content;
    let image = require(`../assets/images/countries/a_default_earth.jpg`);
    if (data) {
      image = require(`../assets/images/countries/${ data.image }`);
      if (visibleLayer) {
        const value = data[visibleLayer];
        if (value) {
          content = (
            <div className="country-stats">
              <div>
                <span>{ t(`dataLabels.${ visibleLayer }`) }</span><span>{ value }</span>
              </div>
            </div>
          );
        } else {
          content = <div>{ t('countryPopup.noDataOnLayer', { country: countryName }) }<strong>{ t(`dataLabels.${ visibleLayer }`) }</strong></div>;
        }
      } else {
        content = (
          <div className="country-stats">
            <div>
              <span>{ t(`dataLabels.population`) }</span><span>{ data.population }</span>
            </div>
            <div>
              <span>{ t(`dataLabels.populationRank`) }</span><span>{ data.populationRank }</span>
            </div>
          </div>
        );
      }
    } else {
      content = <div>{ t('countryPopup.noData', { country: countryName }) }</div>;
    }


    if ( country && trackingVars.countryName != country.name ) {
	gaEvent('user','countrypopup',trackingVars.countryName);
	gaEventTiming('user','countrypopup',trackingVars.countryName);
	if (country.name) trackingVars.countryName = country.name;
    }

    return (
        <>
          <PopupHeader onHide={ onHide } image={ image } badgeText="Country" className="country" />
          <div className="popup-content">
            <h3>{ country.name }</h3>
            { content }
            { data &&
              <Link to={`/country/${ country.name }`}>
                <Button
                  className="popup-button"
                  onClick={ onHide }
                  variant="primary"
                >
                    {t('countryPopup.button')}
                </Button>
              </Link>
            }
          </div>
        </>
    );
}
