import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Cards from 'react-credit-cards';
import { omit } from 'lodash';

import AppLoader from './AppLoader';
import ConfirmDeletePaymentMethod from './ConfirmDeletePaymentMethod';

import api from '../stripeApi';

import './css/ManagePaymentMethods.css';
import 'react-credit-cards/es/styles-compiled.css';

export default function ManagePaymentMethods() {
  const { t } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const isFetchingAuth = useSelector(state => state.auth.isFetching);
  const [isFetchingPaymentMethods, setIsFetchingPaymentMethods] = useState(true);
  const [isPostingPaymentMethod, setIsPostingPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({
    cvc: '',
    exp_month: '',
    exp_year: '',
    number: '',
    name: ''
  });
  const [focus, setFocus] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const handleOpenConfirmDeleteModal = () => setShowConfirmDeleteModal(true);
  const handleHideConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
  // Hardcoded because the type of Stripe's test cards can be misinterpreted.
  const issuer = 'unknown';

  async function handleGetPaymentMethods() {
    if (user) {
      setIsFetchingPaymentMethods(true);

      try {
        const methods = await api.getPaymentMethods({ userId: user.id });
        handleHideConfirmDeleteModal();
        setPaymentMethods(methods.map((method) => ({ ...method, focus: null })));
      } catch (error) {
        console.error('[ManagePaymentMethods] error:', error);
      } finally {
        setIsFetchingPaymentMethods(false);
      }
    }
  }

  async function handleAddPaymentMethod() {
    const payment_method = {
      card: omit(paymentMethod, 'name'),
      billing_details: {
        name: paymentMethod.name
      }
    };

    if (user) {
      setIsPostingPaymentMethod(true);

      try {
        await api.createPaymentMethod({ userId: user.id, payment_method });
        handleGetPaymentMethods();
        setPaymentMethod(
          Object.fromEntries(
            Object.entries(paymentMethod).map((arr) => [arr[0], ''])
          )
        );
        setFocus(null);
      } catch (error) {
        console.error('[ManagePaymentMethods] error:', error);
      } finally {
        setIsPostingPaymentMethod(false);
      }
    }
  }

  async function handleDeletePaymentMethod() {
    const paymentMethodId = selectedPaymentMethod.id;

    if (user) {
      try {
        await api.deletePaymentMethod({ userId: user.id, paymentMethodId });
        handleGetPaymentMethods();
      } catch (error) {
        console.error('[ManagePaymentMethods] error:', error);
      }
    }
  }

  function flipCardOver(paymentMethodId) {
    // react-credit-cards visually highlights the focused property of the card
    // We (ab)use that feature to flip the card over
    setPaymentMethods(paymentMethods.map((paymentMethod) => {
      const focus = paymentMethod.focus ? null : 'cvc';

      return {
        ...paymentMethod,
        ...(paymentMethod.id === paymentMethodId && { focus })
      };
    }));
  }

  function handleInputFocus(e) {
    setFocus(e.target.name);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    setPaymentMethod({ ...paymentMethod, [name]: value });
  }

  useEffect(() => {
    if (user) {
      handleGetPaymentMethods();
    }
  }, [user]);

  return (
    <>
      <AppLoader
        component={ true }
        active={ isFetchingAuth || isFetchingPaymentMethods || isPostingPaymentMethod }
      />
      {
        paymentMethods.map((paymentMethod, index) => (
          <div
            key={ `payment-method-${index}` }
            className="payment-method-container"
          >
            <div
              className="payment-method-wrapper"
              onClick={ () => flipCardOver(paymentMethod.id) }
            >
              <Cards
                cvc="***"
                expiry={ `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}` }
                name={ paymentMethod.billing_details.name }
                number={ `************${paymentMethod.card.last4}` }
                focused={ paymentMethod.focus }
                preview={ true }
                issuer={ issuer } />
            </div>
            <Button
              className="mt-4"
              variant="danger"
              onClick={ () => {
                setSelectedPaymentMethod(paymentMethod);
                handleOpenConfirmDeleteModal();
              } }
            >
              <FontAwesomeIcon icon={faTrashAlt} /> { t('managePaymentMethods.delete') }
            </Button>
          </div>
        ))
      }
      { paymentMethods.length === 0 && !isFetchingPaymentMethods &&
        <div className="payment-method-container">
          <Cards
            cvc={ `${paymentMethod.cvc}` }
            expiry={ `${paymentMethod.exp_month}/${paymentMethod.exp_year}` }
            name={ `${paymentMethod.name}` }
            number={ `${paymentMethod.number}` }
            focused={ focus }
            preview={ true }
            issuer={ issuer } />
          <Form className="mt-4 align-self-center">
            <Form.Control
              id="number"
              name="number"
              type="text"
              placeholder={ t('managePaymentMethods.card.number') }
              onChange={ handleInputChange }
              onFocus={ handleInputFocus }
            />
            <Form.Control
              id="name"
              name="name"
              type="text"
              placeholder={ t('managePaymentMethods.card.name') }
              onChange={ handleInputChange }
              onFocus={ handleInputFocus }
            />
            <div className="d-flex align-items-center">
              <Form.Control
                id="exp-month"
                name="exp_month"
                type="tel"
                placeholder={ t('managePaymentMethods.card.expMonth') }
                onChange={ handleInputChange }
                onFocus={ handleInputFocus }
              />
              <h6 className="m-0">/</h6>
              <Form.Control
                id="exp-year"
                name="exp_year"
                type="tel"
                placeholder={ t('managePaymentMethods.card.expYear') }
                onChange={ handleInputChange }
                onFocus={ handleInputFocus }
              />
            </div>
            <Form.Control
              id="cvc"
              className="w-50"
              name="cvc"
              type="tel"
              placeholder={ t('managePaymentMethods.card.cvc') }
              onChange={ handleInputChange }
              onFocus={ handleInputFocus }
            />
            <Button
              className="mt-2"
              variant="primary"
              block
              onClick={ handleAddPaymentMethod }
            >
              <FontAwesomeIcon icon={faPaperPlane} /> { t('managePaymentMethods.save') }
            </Button>
          </Form>
        </div>
      }

      <ConfirmDeletePaymentMethod
        show={ showConfirmDeleteModal }
        hide={ handleHideConfirmDeleteModal }
        onSubmit={ handleDeletePaymentMethod } />
    </>
    );
}
